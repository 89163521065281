import update from 'immutability-helper';
import _ from 'lodash';
import * as moment from 'moment';

import {calcCostFromMargin, calcRevenueFromMargin} from '../../../../../../../util/moneymath.ts';
import {roundFloat} from '~/util/numbers';
import {
	GET_WORK_VIEW_AASDI_STATUS_SUCCESS,
	GET_WORK_VIEW_AASDI_STATUS_FAILURE,
	WORK_VIEW_PUSH_INSERTED_BILLABLE_ITEM,
	WORK_VIEW_PUSH_UPDATED_BILLABLE_ITEM,
	WORK_VIEW_PUSH_DELETED_BILLABLE_ITEM,
	WORK_VIEW_CLEAR_INSERTED_BILLABLE_ITEM_DATA
} from './workRecordActions.js';

const pushOrUpdate = (existingItems, itemToPush) => {
	const itemIndex = existingItems.findIndex((item) => item.id === itemToPush.id);
	if (itemIndex !== -1) {
		existingItems[itemIndex] = itemToPush;
		return _.cloneDeep(existingItems);
	} else {
		return [...existingItems, itemToPush];
	}
};
const getBillableItem = (state, changedProperty, index) => {
	return {
		...state.view.BillingCenter.projectCostingSpreadsheetModal.spreadSheetData[index],
		...changedProperty
	};
};

const defaultFields = [
	{
		name: 'Bill Name',
		value: '',
		input: true,
		info: true,
		error: false,
		columnWidth: 5,
		required: true
	},
	{
		name: 'Billing Contact',
		value: null,
		options: [],
		info: true,
		error: false,
		columnWidth: 5,
		dropdown: true,
		searchBillingContact: true,
		loading: false,
		required: true
	},
	{
		name: 'Freight',
		value: 0,
		input: true,
		info: true,
		error: false,
		columnWidth: 5
	},
	{
		name: 'PO #',
		radio: 'existing',
		value: null,
		toggle: true,
		columnWidth: 6,
		error: false,
		// loading: false,
		// searchPO: true,
		options: [],
		existingType: true
	},
	{
		name: 'Approval Document Name',
		value: null,
		columnWidth: 6,
		error: false,
		newType: true
	},
	{
		name: 'Approval Google Drive Link',
		value: null,
		columnWidth: 6,
		error: false,
		newType: true
	},
	{
		name: 'Billing SOW',
		value: null
	}
];

const defaultState = {
	toggleDetailModal: false,
	closureModal: {
		toggleClosureModal: false,
		stage: ''
	},
	aasdiRatingModal: {
		toggleRatingConfirmationModal: false
	},
	canceledModal: {
		toggleCanceledModal: false
	},
	requestToCBWModal: {
		toggleCBWModal: false
	},
	loading: null,
	view: {
		id: null,
		activeStep: 'Overview',
		navbarLoading: null,
		record: {},
		overview: {
			progressStep: {
				activeStep: 'In Queue',
				closedProgress: false
			},
			orderDetails: {
				loading: false,
				serviceSubtypeValue: 2,
				fields: [
					{
						name: 'Project Manager',
						value: 'Not defined',
						info: true,
						error: false,
						columnWidth: 5,
						employee: true
					},
					{
						name: 'Project Administrator',
						value: 'No Project Administrator Selected',
						info: true,
						error: false,
						columnWidth: 5,
						employee: true,
						options: []
					},
					{
						name: 'Sales Representative',
						value: 'No Sales Representative Selected',
						info: true,
						error: false,
						columnWidth: 5,
						employee: true,
						options: []
					},
					{
						name: 'Purchase Order',
						value: 'No Purchase Order Selected',
						info: true,
						error: false,
						columnWidth: 5
					},
					{
						name: 'ASD Job Number',
						value: 'No Number Selected',
						info: true,
						error: false,
						columnWidth: 5
					},
					{
						name: 'Service Type',
						// value: 'No Service Type Selected',
						info: true,
						dropdown: true,
						error: false,
						columnWidth: 5,
						options: [
							{
								key: 1,
								text: 'Deploy',
								value: 1
							},
							{
								key: 2,
								text: 'Design',
								value: 2
							},
							{
								key: 3,
								text: 'Build',
								value: 3
							},
							{
								key: 4,
								text: 'Support',
								value: 4
							}
						]
					},
					{
						name: 'Work Category',
						value: null,
						info: true,
						dropdown: true,
						error: false,
						columnWidth: 5,
						options: [
							{
								key: 'Access Control & Surveillance',
								text: 'Access Control & Surveillance',
								value: 'access control & surveillance'
							},
							{
								key: 'Audio Visual',
								text: 'Audio Visual',
								value: 'audio visual'
							},
							{
								key: 'Network Integration & Telephony',
								text: 'Network Integration & Telephony',
								value: 'network integration & telephony'
							},
							{
								key: 'Point of Sale',
								text: 'Point of Sale',
								value: 'point of sale'
							},
							{
								key: 'Power & Electrical',
								text: 'Power & Electrical',
								value: 'power & electrical'
							},
							{
								key: 'Sound Masking & Paging',
								text: 'Sound Masking & Paging',
								value: 'sound masking & paging'
							},
							{
								key: 'Structured Cabling',
								text: 'Structured Cabling',
								value: 'structured cabling'
							},
							// {
							// 	key: 'LED Lighting',
							// 	text: 'LED Lighting',
							// 	value: 'LED Lighting'
							// },
							{
								key: 'Fire Alarm',
								text: 'Fire Alarm',
								value: 'fire alarm'
							}
						]
					},
					{
						name: 'AASDI',
						value: 'No AASDI Selected',
						info: true,
						error: false,
						columnWidth: 5
					},
					{
						name: 'Client',
						value: 'No Client',
						info: true,
						error: false,
						columnWidth: 5,
						disabled: true,
						client: true
					},
					{
						name: 'Spectrum Code',
						value: '',
						info: true,
						error: false,
						columnWidth: 5,
						disabled: true
					},
					{
						name: 'Quoted Job Number',
						value: 'No quoted job',
						info: true,
						error: false,
						columnWidth: 5
					},
					{
						name: 'Start Date',
						value: 'No start date defined',
						info: true,
						error: false,
						columnWidth: 5
					},
					{
						name: 'End Date',
						value: 'No end date defined',
						info: true,
						error: false,
						columnWidth: 5
					},
					{
						name: 'Google Drive Link',
						value: 'No Link Selected',
						info: true,
						error: false,
						columnWidth: 5,
						googleDrive: true,
						iconName: 'google drive'
					},
					{
						name: 'Master Client',
						value: 'No Master Client',
						spectrumCode: true,
						info: true,
						error: false,
						columnWidth: 5,
						false: true,
						options: [],
						masterClient: true,
						inputLoading: false
					},
					{
						name: 'Technologies',
						value: 'No Technologies',
						info: true,
						tech: true,
						error: false,
						columnWidth: 5
					},
					{
						name: 'Contact',
						value: '',
						loading: false,
						contact: true,
						error: false,
						columnWidth: 16,
						inputLoading: false,
						options: []
					},
					{
						name: 'Address',
						value: {},
						loading: false,
						address: true,
						error: false,
						columnWidth: 16,
						inputLoading: false,
						options: []
					}
				]
			},
			sow: {
				loading: true,
				id: 0,
				value: ''
			}
		},
		Documents: {
			loading: true,
			fields: [
				{
					name: 'File Name',
					value: '',
					input: true,
					error: false,
					disabled: false,
					required: true,
					checkForm: true,
					columnWidth: 5
				},
				{
					name: 'Google Drive Link',
					value: '',
					input: true,
					error: false,
					disabled: false,
					checkForm: true,
					required: true,
					columnWidth: 5
				},
				{
					name: 'Customer Approval (Purchase Order)',
					value: '',
					input: false,
					dropdown: true,
					error: false,
					disabled: false,
					required: false,
					columnWidth: 5,
					options: [
						{
							key: 'No',
							text: 'No',
							value: false
						},
						{
							key: 'Yes',
							text: 'Yes',
							value: true
						}
					]
				},
				{
					name: 'Comments',
					value: '',
					input: false,
					textArea: true,
					error: false,
					disabled: false,
					required: false,
					columnWidth: 15
				}
			],
			documents: []
		},
		ProjectCosting: {
			ChangeOrders: {
				grid: {
					data: []
				}
			},
			projectCostingPJ: {
				loading: true,
				summaryFields: {
					revenue: [
						{
							name: 'Estimated Revenue:',
							value: null
						},
						{
							name: 'Estimated CO Revenue:',
							value: null
						},
						{
							name: 'Estimated Total Revenue:',
							value: null
						},
						{
							name: 'Actual CO Revenue:',
							value: null
						},
						{
							name: 'Actual Revenue:',
							value: null
						}
					],
					cost: [
						{
							name: 'Estimated Starting Cost:',
							value: null
						},
						{
							name: 'Estimated CO Cost:',
							value: null
						},
						{
							name: 'Estimated Total Cost:',
							value: null
						},
						{
							name: 'Actual CO Cost:',
							value: null
						},
						{
							name: 'Actual Cost:',
							value: null
						},
						{
							name: 'Over/Under Budget:',
							value: null
						}
					],
					marginGP: [
						{
							name: 'Estimated Margin:',
							percent: true,
							value: null
						},
						{
							name: 'Estimated Gross Profit:',
							value: null
						},
						{
							name: 'Actual Margin:',
							percent: true,
							value: null
						},
						{
							name: 'Actual Gross Profit:',
							value: null
						},
						{
							name: 'Remaining to Bill:',
							value: null
						}
					]
				}
			},
			projectCostingWO: {
				purchaseOrdersLoading: true,
				purchaseOrdersGrid: {
					rowData: []
				},
				AASDIInvoicesGrid: {
					rowData: []
				}
			}
		},
		// Logic around financials tab is in here as well
		BillingCenter: {
			invoiceLoading: false,
			hasPendingInvoices: false,
			invoiceRowData: [],
			originalEstimatesLoaded: false,
			originalEstimates: [],
			// Used for for showing the bill SOW and Contact on selection
			currentSelectedInvoiceIndex: null,
			currentBillingContactOptions: {
				loading: null,
				options: []
			},
			invoiceDetailsRowData: [],
			invoicingGridRowData: [],
			// Used for bill submit and remove
			actionBillModal: {
				actionBillModalToggle: false,
				place: null,
				loading: false,
				data: null,
				rowIndex: null
			},
			// Used on create bill and editing bill items
			projectCostingSpreadsheetModal: {
				billableItemsRequiredError: false,
				loading: null,
				billIndex: null,
				toggle: false,
				marginAllMaterial: 0,
				marginAllLabor: 0,
				skuLoaded: false,
				SKUs: [],
				spreadSheetData: [],
				updatedBillableItems: [],
				deletedBillableItems: [],
				selectSKUModal: {
					toggle: false,
					SKUList: []
				},
				fields: _.cloneDeep(defaultFields)
			},
			/////////////////////////////////////////////////
			orderGroup: {
				rowData: [],
				projectGroups: {},
				addOrderGroupModal: {
					toggle: false,
					loading: null,
					rowData: [],
					fields: [
						{
							name: 'Description',
							value: '',
							input: true,
							info: true,
							error: false,
							required: true
						},
						{
							name: 'Purchase Order #',
							value: '',
							input: true,
							info: true,
							error: false,
							required: false
						},
						{
							name: 'Quoted Job #',
							value: null,
							quotedJobNumber: true,
							toggle: true,
							input: true,
							error: false,
							disabled: true,
							required: true
						},
						{
							name: 'Billing Contact',
							value: null,
							options: [],
							info: true,
							error: false,
							dropdown: true,
							searchBillingContact: true,
							loading: false,
							required: true
						}
					],
					selectWOModal: {
						toggle: false,
						rowData: []
					}
				},
				oldGroup: {},
				///////////////////////////////////////////
				submitOrderGroupModal: {
					toggle: false,
					data: null,
					rowIndex: null,
					loading: false
				},
				removeGroupConfirmationModel: {
					toggle: false,
					data: null,
					rowIndex: null,
					comment: ''
				}
			}
		},
		eventLog: {
			loading: false,
			colDefs: [
				{
					headerName: 'Date and Time',
					field: 'createdOn',
					width: 300
				},
				{
					headerName: 'User',
					field: 'loggedBy',
					width: 300
				},
				{
					headerName: 'Description',
					field: 'description',
					width: 800
				},
				{
					headerName: 'Event',
					field: '',
					width: 200
				}
			],
			eventLogs: [],

			aasdiStatusLoading: true,
			aasdiStatusError: false,
			// constants really shouldn't be kept in the reducer, but for consistency I'm going to add them here
			aasdiStatusColDefs: [
				{
					headerName: 'Date and Time',
					field: 'checkTime',
					filter: 'agDateColumnFilter',
					width: 300
				},
				{
					headerName: 'AASDI',
					field: 'aasdiName',
					width: 300
				},
				{
					headerName: 'Description',
					field: 'description',
					flex: 1
				}
			],
			aasdiStatusRecords: []
		},
		orders: {
			loading: false,
			colDefs: [
				{
					headerName: 'Detail',
					field: '',
					width: 80,
					cellRenderer: 'clientWorkTabCellRenderer'
				},
				{
					headerName: 'Work Number',
					field: 'workNumber',
					width: 150,
					filter: 'agTextColumnFilter'
				},
				{
					headerName: 'Status',
					field: 'status_Name',
					width: 200,
					filter: 'agTextColumnFilter'
				},
				{
					headerName: 'Issue Date',
					field: 'createdOn',
					width: 200,
					filter: 'agDateColumnFilter'
				},
				{
					headerName: 'Project Administrator',
					field: 'workManager_Name',
					width: 100,
					filter: 'agNumberColumnFilter'
				},
				{
					headerName: 'Store Number',
					field: 'location_StoreNumber',
					width: 150,
					filter: 'agNumberColumnFilter'
				},
				{
					headerName: 'Location Name',
					field: 'location_Name',
					width: 300,
					filter: 'agTextColumnFilter'
				},
				{
					headerName: 'AASDI',
					field: 'client_Name',
					width: 150,
					filter: 'agTextColumnFilter'
				}
			],
			orders: []
		},
		manageAASDI: {
			loading: false,
			noTrip: false,
			aasdis: [],
			tripOptions: [],
			// workAasdiIdCheck and tripIdCheck used to check if functions should run on component did mount for aasdiInfoSection
			workAasdiIdCheck: '',
			tripIdCheck: '',
			selectedWorkAasdiId: '',
			selectedTripId: '',
			contactObject: {
				name: '',
				title: '',
				phone: '',
				email: '',
				actualDate: '',
				requestedDate: '',
				id: ''
			},
			modal: {
				toggle: false,
				search: {
					loading: false,
					zip: '',
					radius: 25,
					name: '',
					results: [],
					zipError: false
				},
				radiusOptions: [
					{key: '10', text: 10, value: 10},
					{key: '25', text: 25, value: 25},
					{key: '50', text: 50, value: 50},
					{key: '75', text: 75, value: 75},
					{key: '100', text: 100, value: 100},
					{key: '150', text: 150, value: 150},
					{key: '200', text: 200, value: 200},
					{key: '>200', text: '>200', value: '>200'}
				]
			},
			createTrip: {
				modal: {
					open: false,
					contactOptions: [],
					loading: false,
					fields: [
						{
							name: 'Trip Name',
							value: '',
							input: true,
							error: false,
							columnWidth: 16,
							loading: false
						},
						{
							name: 'Contact',
							value: {},
							contact: true,
							error: false,
							columnWidth: 16,
							loading: false
						},
						{
							name: 'Requested Date',
							value: '',
							time: true,
							columnWidth: 7
						},
						{
							name: 'Actual Date',
							value: '',
							time: true,
							columnWidth: 7
						},
						{
							name: 'AASDI SOW',
							value: '',
							textArea: true,
							error: false,
							saved: true,
							columnWidth: 16
						},
						{
							name: 'Special Instructions',
							value: '',
							textArea: true,
							error: false,
							saved: true,
							columnWidth: 16
						}
					]
				}
			},
			infoSection: [
				{
					side: 'left',
					columnWidth: 7,
					sections: [
						{
							name: 'Basic Info',
							loading: true,
							fields: [
								{
									name: 'Contact',
									value: '',
									loading: false,
									input: true,
									contact: true,
									error: false,
									columnWidth: 16
								},
								{
									name: 'Trip Name',
									value: '',
									nameDisplay: true,
									contact: true,
									error: false,
									columnWidth: 5
								},
								{
									name: 'Requested Date',
									value: '',
									time: true,
									columnWidth: 5
								},
								{
									name: 'Actual Date',
									value: '',
									time: true,
									columnWidth: 5
								}
							]
						},
						{
							name: 'Activity Stream',
							loading: true,
							activityStream: true,
							id: ''
						}
					]
				},
				{
					side: 'right',
					columnWidth: 9,
					sections: [
						{
							name: 'AASDI SOW',
							loading: true,
							fields: [
								{
									name: 'AASDI SOW',
									value: '',
									textArea: true,
									error: false,
									saved: true,
									columnWidth: 16
								}
							]
						},
						{
							name: 'Special Instructions',
							loading: true,
							fields: [
								{
									name: 'Special Instructions',
									value: '',
									textArea: true,
									error: false,
									saved: true,
									columnWidth: 16
								}
							]
						},
						{
							name: 'Contacts Section',
							loading: true,
							contactsSection: true
						}
					]
				}
			]
		}
	}
};

export default (state = defaultState, action) => {
	switch (action.type) {
		case 'CLEAR_WORK': {
			state = defaultState;
			break;
		}
		case 'GET_WORK_OVERVIEW':
			state = update(state, {
				view: {
					id: {
						$set: action.payload.json.id
					},
					record: {
						$set: action.payload.json
					},
					overview: {
						sow: {
							value: {
								$set: action.payload.json.scopeOfWork
							},
							id: {
								$set: action.payload.json.id
							}
						},
						progressStep: {
							activeStep: {
								$set: action.payload.json.status_Name
							}
						},
						orderDetails: {
							serviceSubtypeValue: {
								$set: action.payload.json.serviceSubType
							},
							fields: {
								[state.view.overview.orderDetails.fields.findIndex(
									(fields) => fields.name === 'Start Date'
								)]: {
									value: {
										$set: action.payload.json.opportunityData
											? action.payload.json.opportunityData.startDate
											: action.payload.json.startDate
									}
								},
								[state.view.overview.orderDetails.fields.findIndex(
									(fields) => fields.name === 'End Date'
								)]: {
									value: {
										$set: action.payload.json.opportunityData
											? action.payload.json.opportunityData.endDate
											: action.payload.json.endDate
									}
								},
								[state.view.overview.orderDetails.fields.findIndex(
									(fields) => fields.name === 'Project Manager'
								)]: {
									value: {
										$set: action.payload.json.projectManager_Name
									}
								},
								[state.view.overview.orderDetails.fields.findIndex(
									(fields) => fields.name === 'Project Administrator'
								)]: {
									value: {
										$set: action.payload.json.workManager_Name
									}
								},
								[state.view.overview.orderDetails.fields.findIndex(
									(fields) => fields.name === 'Sales Representative'
								)]: {
									value: {
										$set: action.payload.json.accountExecutive_Name
									}
								},
								[state.view.overview.orderDetails.fields.findIndex(
									(fields) => fields.name === 'Purchase Order'
								)]: {
									value: {
										$set: action.payload.json.purchaseOrderNumber
									}
								},
								[state.view.overview.orderDetails.fields.findIndex(
									(fields) => fields.name === 'ASD Job Number'
								)]: {
									value: {
										$set: action.payload.json.workNumber
									}
								},
								[state.view.overview.orderDetails.fields.findIndex(
									(fields) => fields.name === 'Google Drive Link'
								)]: {
									value: {
										$set: action.payload.json.googleDriveLink
									}
								},
								[state.view.overview.orderDetails.fields.findIndex(
									(fields) => fields.name === 'Quoted Job Number'
								)]: {
									value: {
										$set: action.payload.json.quotedJobNumber
									}
								},
								[state.view.overview.orderDetails.fields.findIndex(
									(fields) => fields.name === 'Service Type'
								)]: {
									value: {
										$set: action.payload.json.serviceType
									}
								},
								[state.view.overview.orderDetails.fields.findIndex(
									(fields) => fields.name === 'Work Category'
								)]: {
									value: {
										$set: action.payload.json.workCategory
									}
								},
								[state.view.overview.orderDetails.fields.findIndex(
									(fields) => fields.name === 'Spectrum Code'
								)]: {
									value: {
										$set: action.payload.json.spectrumCode
									}
								},
								[state.view.overview.orderDetails.fields.findIndex(
									(fields) => fields.name === 'AASDI'
								)]: {
									value: {
										$set: action.payload.json.client_Name
									}
								},
								[state.view.overview.orderDetails.fields.findIndex(
									(fields) => fields.name === 'Client'
								)]: {
									value: {
										$set: action.payload.json.client_Name
									}
								},
								[state.view.overview.orderDetails.fields.findIndex(
									(fields) => fields.name === 'Master Client'
								)]: {
									value: {
										$set: action.payload.json.masterClientName
									},
									spectrumCode: {
										$set: action.payload.json.masterClientCode
									}
								},
								[state.view.overview.orderDetails.fields.findIndex(
									(fields) => fields.name === 'Technologies'
								)]: {
									value: {
										$set: action.payload.json.tech
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'CLOSURE_MODAL_TOGGLE':
			state = update(state, {
				closureModal: {
					toggleClosureModal: {
						$set: !state.closureModal.toggleClosureModal
					},
					stage: {
						$set: action.payload.stage
					}
				}
			});
			break;

		case 'AASDI_RATING_MODAL_TOGGLE':
			state = update(state, {
				aasdiRatingModal: {
					toggleRatingConfirmationModal: {
						$set: !state.aasdiRatingModal.toggleRatingConfirmationModal
					}
				}
			});
			break;

		case 'CANCELED_MODAL_TOGGLE':
			state = update(state, {
				canceledModal: {
					toggleCanceledModal: {
						$set: !state.canceledModal.toggleCanceledModal
					}
				}
			});
			break;

		case 'CBW_MODAL_TOGGLE':
			state = update(state, {
				requestToCBWModal: {
					toggleCBWModal: {
						$set: !state.requestToCBWModal.toggleCBWModal
					}
				}
			});
			break;

		case 'WORK_OVERVIEW_ORDER_DETAILS_LOADING_FIELD':
			if (action.payload.contactLoading) {
				state = update(state, {
					view: {
						overview: {
							orderDetails: {
								loading: {
									$set: action.payload.loading
								}
							}
						}
					}
				});
			} else {
				state = update(state, {
					view: {
						overview: {
							orderDetails: {
								loading: {
									$set: action.payload.loading
								}
							}
						}
					}
				});
			}
			break;

		case 'UPDATE_WORK_OVERVIEW_BILLING_CONTACT':
			state = update(state, {
				view: {
					overview: {
						orderDetails: {
							fields: {
								[state.view.overview.orderDetails.fields.findIndex(
									(fields) => fields.name === 'Contact'
								)]: {
									value: {
										$set: action.payload.value
									},
									loading: {
										$set: false
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'UPDATE_WORK_OVERVIEW_LOCATION':
			state = update(state, {
				view: {
					overview: {
						orderDetails: {
							fields: {
								[state.view.overview.orderDetails.fields.findIndex(
									(fields) => fields.name === 'Address'
								)]: {
									value: {
										$set: action.payload.value
									},
									loading: {
										$set: false
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'WORK_CHANGE_VIEW_STEP':
			state = update(state, {
				view: {
					activeStep: {
						$set: action.payload.value
					}
				}
			});
			break;

		case 'WORK_VIEW_WORK_ID':
			state = update(state, {
				view: {
					id: {
						$set: action.payload.workId
					}
				}
			});
			break;

		case 'UPDATE_WORK_VIEW_DOCUMENTS_VALUE':
			state = update(state, {
				view: {
					Documents: {
						fields: {
							[action.payload.index]: {
								value: {
									$set: action.payload.value
								}
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_AASDI_LOADING_FIELD':
			state = update(state, {
				view: {
					manageAASDI: {
						loading: {
							$set: action.payload.loading
						}
					}
				}
			});
			break;

		case 'GET_WORK_VIEW_AASDI':
			state = update(state, {
				view: {
					manageAASDI: {
						aasdis: {
							$set: action.payload.json
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_SELECT_AASDI':
			state = update(state, {
				view: {
					manageAASDI: {
						aasdis: {
							[action.payload.index]: {
								selected: {
									$set: action.payload.selected
								}
							}
						},
						selectedWorkAasdiId: {
							$set: action.payload.id
						}
					}
				}
			});
			break;

		case 'SET_WORK_VIEW_AASDI_ID_CHECK':
			state = update(state, {
				view: {
					manageAASDI: {
						workAasdiIdCheck: {
							$set: action.payload.id
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_DOCUMENTS_LOADING':
			state = update(state, {
				view: {
					Documents: {
						loading: {
							$set: action.payload
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_ADD_DOCUMENT':
			state = update(state, {
				view: {
					Documents: {
						documents: {
							$push: [action.payload.object]
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_REMOVE_DOCUMENT':
			state = update(state, {
				view: {
					Documents: {
						documents: {
							$splice: [[action.payload.index, 1]]
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_UPDATE_DOCUMENT':
			state = update(state, {
				view: {
					Documents: {
						documents: {
							[action.payload.index]: {
								customerApproval: {
									$set: action.payload.value
								}
							}
						}
					}
				}
			});
			break;

		case 'GET_WORK_VIEW_DOCUMENTS':
			state = update(state, {
				view: {
					Documents: {
						documents: {
							$set: action.payload.json
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_EVENTLOG_GRID_LOADING_FIELD':
			state = update(state, {
				view: {
					eventLog: {
						loading: {
							$set: action.payload.loading
						}
					}
				}
			});
			break;

		case 'GET_WORK_VIEW_EVENTLOG':
			state = update(state, {
				view: {
					eventLog: {
						eventLogs: {
							$set: action.payload.json
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_BILL_ORIGINAL_ESTIMATES_SET_LOADED':
			state = update(state, {
				view: {
					BillingCenter: {
						originalEstimatesLoaded: {$set: action.payload}
					}
				}
			});
			break;

		case 'WORK_VIEW_BILL_SET_ORIGINAL_ESTIMATES':
			state = update(state, {
				view: {
					BillingCenter: {
						originalEstimates: {$set: action.payload}
					}
				}
			});
			break;

		case 'WORK_VIEW_BILL_ORIGINAL_ESTIMATES_REMOVE':
			const replacement = state.view.BillingCenter.originalEstimates.filter(
				(val) => !action.payload.includes(val.id)
			);
			state = update(state, {
				view: {
					BillingCenter: {
						originalEstimates: {
							$set: replacement
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_PROJECT_SPREADSHEET_MODAL_TOGGLE':
			//payload.billIndex and fields are null when closing the modal or making a new bill
			const opening = !state.view.BillingCenter.projectCostingSpreadsheetModal.toggle;

			const fields = _.cloneDeep(defaultFields);
			if (opening) {
				if (action.payload.billIndex !== null) {
					//for exiting a bill, fill out fields needed
					const bill = state.view.BillingCenter.invoiceRowData[action.payload.billIndex];

					fields[0].value = bill.billName;
					fields[0].disabled = true;
					fields[1].value = bill.billingContactId;
					fields[1].options.push({
						value: bill.billingContactId,
						text: bill.billingContactName
					});
					fields[2].value = String(bill.freight);
					fields[3].value = bill.purchaseOrderNumber;
					fields[6].value = bill.billingSow;
				} else {
					//for creating a bill, copy over values from work order
					fields[3].value = state.view.record.purchaseOrderNumber;
					fields[6].value = state.view.record.scopeOfWork;
				}
			}

			state = update(state, {
				view: {
					BillingCenter: {
						projectCostingSpreadsheetModal: {
							billIndex: {$set: action.payload.billIndex},
							toggle: {$set: opening},
							loading: {$set: null},
							spreadSheetData: {
								$set: action.payload.spreadSheetData
							},
							fields: {$set: fields}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_COMPLETE_PROJECT_COSTING_SPREADSHEET':
			state = update(state, {
				view: {
					BillingCenter: {
						invoiceRowData: {
							[action.payload.billIndex]: {
								rowData: {$set: action.payload.rowData}
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_PROJECT_SPREADSHEET_MODAL_ADD_ROW':
			state = update(state, {
				view: {
					BillingCenter: {
						projectCostingSpreadsheetModal: {
							spreadSheetData: {
								$push: [action.payload.object]
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_PROJECT_SPREADSHEET_MODAL_REMOVE_ROW':
			state = update(state, {
				view: {
					BillingCenter: {
						projectCostingSpreadsheetModal: {
							spreadSheetData: {
								$splice: [[action.payload.rowIndex, 1]]
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_BILL_MODAL_UPDATE_MATERIAL_MARGIN':
			state = update(state, {
				view: {
					BillingCenter: {
						projectCostingSpreadsheetModal: {
							marginAllMaterial: {$set: action.payload.value}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_BILL_MODAL_UPDATE_LABOR_MARGIN':
			state = update(state, {
				view: {
					BillingCenter: {
						projectCostingSpreadsheetModal: {
							marginAllLabor: {$set: action.payload.value}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_BILL_MODAL_APPLY_MATERIAL_MARGIN':
			const maginAllMaterial = state.view.BillingCenter.projectCostingSpreadsheetModal.marginAllMaterial;
			const materialMapFn = (sku) => {
				if (sku.unitMaterialCost === 0) {
					return sku;
				}
				return update(sku, {
					materialResell: {
						$set: roundFloat(
							calcRevenueFromMargin(sku.unitMaterialCost * sku.quantity, maginAllMaterial),
							2
						)
					}
				});
			};

			state = update(state, {
				view: {
					BillingCenter: {
						projectCostingSpreadsheetModal: {
							spreadSheetData: {
								$apply: (data) => data.map(materialMapFn)
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_BILL_MODAL_APPLY_LABOR_MARGIN':
			const marginAllLabor = state.view.BillingCenter.projectCostingSpreadsheetModal.marginAllLabor;
			const laborMapFn = (sku) => {
				if (sku.unitMaterialCost === 0) {
					return sku;
				}
				return update(sku, {
					laborResell: {
						$set: roundFloat(calcRevenueFromMargin(sku.unitLaborCost * sku.quantity, marginAllLabor), 2)
					}
				});
			};
			state = update(state, {
				view: {
					BillingCenter: {
						projectCostingSpreadsheetModal: {
							spreadSheetData: {
								$apply: (data) => data.map(laborMapFn)
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_SELECT_SKU_MODAL_TOGGLE':
			state = update(state, {
				view: {
					BillingCenter: {
						projectCostingSpreadsheetModal: {
							selectSKUModal: {
								toggle: {
									$set: action.payload.toggle
								},
								SKUList: {
									$set: action.payload.SKUList
								}
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_SELECT_SKU_MODAL_ADD_ITEMS':
			state = update(state, {
				view: {
					BillingCenter: {
						projectCostingSpreadsheetModal: {
							spreadSheetData: {
								[action.payload.spreadSheetDataEmptyIndex]: {
									$set: action.payload.object
								}
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_PROJECT_BILLABLEITEM_UPDATE':
			state = update(state, {
				view: {
					BillingCenter: {
						projectCostingSpreadsheetModal: {
							spreadSheetData: {
								[action.payload.index]: {
									$merge: action.payload.value
								}
							}
						}
					}
				}
			});
			break;
		case 'WORK_VIEW_PROJECT_SPREADSHEET_MODAL_GRID_UPDATE_SKU':
			state = update(state, {
				view: {
					BillingCenter: {
						projectCostingSpreadsheetModal: {
							spreadSheetData: {
								[action.payload.rowIndex]: {
									$set: action.payload.object
								}
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_PROJECT_SPREADSHEET_MODAL_GRID_UPDATE':
			state = update(state, {
				view: {
					BillingCenter: {
						projectCostingSpreadsheetModal: {
							spreadSheetData: {
								[action.payload.rowIndex]: {
									[action.payload.field]: {
										$set: action.payload.value
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_PROJECT_SPREADSHEET_MODAL_GRID_UPDATE_TOTAL':
			state = update(state, {
				view: {
					BillingCenter: {
						projectCostingSpreadsheetModal: {
							[action.payload.field]: {
								$set: action.payload.value
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_CREATE_BILL':
			state = update(state, {
				view: {
					BillingCenter: {
						invoiceRowData: {
							$push: [action.payload]
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_BILLING_CREATE_MODAL_RADIO':
			state = update(state, {
				view: {
					BillingCenter: {
						projectCostingSpreadsheetModal: {
							fields: {
								[action.payload.fieldIndex]: {
									radio: {
										$set: action.payload.value
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_BILLING_CREATE_MODAL_UPDATE':
			state = update(state, {
				view: {
					BillingCenter: {
						projectCostingSpreadsheetModal: {
							fields: {
								[action.payload.fieldIndex]: {
									value: {
										$set: action.payload.value
									},
									error: {
										$set: action.payload.error
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_BILLING_MODAL_SET_REQUIRED_ITEMS_ERROR':
			state = update(state, {
				view: {
					BillingCenter: {
						projectCostingSpreadsheetModal: {
							billableItemsRequiredError: {
								$set: action.payload.value
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_CREATE_BILL_FREIGHT_DOLLAR_AMOUNT_ERROR':
			state = update(state, {
				view: {
					BillingCenter: {
						projectCostingSpreadsheetModal: {
							fields: {
								[2]: {
									error: {
										$set: true
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_INVOICE_SELECTED':
			state = update(state, {
				view: {
					BillingCenter: {
						invoiceDetailsRowData: {
							$set: action.payload.rowData
						},
						currentBillingContactOptions: {
							options: {
								$set: [action.payload.contactOption]
							}
						},
						currentSelectedInvoiceIndex: {
							$set: action.payload.rowIndex
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_INVOICING_GRID_DATA':
			state = update(state, {
				view: {
					BillingCenter: {
						invoicingGridRowData: {
							$set: action.payload.rowData
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_UPDATE_FIELD': {
			let orderDetails = state.view.overview.orderDetails;
			let fieldIndex = orderDetails.fields.findIndex((fields) => fields.name === action.payload.name);
			// if (fieldIndex === -1) {
			// 	throw new Error(`unknown field name ${action.payload.name}`);
			// }

			state = update(state, {
				view: {
					overview: {
						orderDetails: {
							fields: {
								[fieldIndex]: {
									value: {
										$set: action.payload.value
									},
									error: {
										$set: action.payload.error
									}
								}
							}
						}
					}
				}
			});
			break;
		}

		case 'UPDATE_GOOGLE_DRIVE_LINK':
			state = update(state, {
				view: {
					record: {
						googleDriveLink: {
							$set: action.payload.value
						}
					}
				}
			});
			break;

		case 'ADD_UNRATED_AASDI':
			let newUnratedAasdis = state.view.record.unratedAasdis ? state.view.record.unratedAasdis : [];
			newUnratedAasdis.push(action.payload.newUnratedAasdi);

			state = update(state, {
				view: {
					record: {
						unratedAasdis: {
							$set: newUnratedAasdis
						}
					}
				}
			});
			break;

		case 'REMOVE_UNRATED_AASDI':
			let unratedAasdis = state.view.record.unratedAasdis;
			let index = unratedAasdis.findIndex((aasdi) => aasdi.aasdiId === action.payload.aasdiId);
			unratedAasdis.splice(index, 1);

			state = update(state, {
				view: {
					record: {
						unratedAasdis: {
							$set: unratedAasdis
						}
					}
				}
			});
			break;

		case 'UPDATE_SERVICE_SUBTYPE_FIELD':
			state = update(state, {
				view: {
					overview: {
						orderDetails: {
							serviceSubtypeValue: {
								$set: action.payload.value
							}
						}
					}
				}
			});
			break;

		case 'SET_OPTIONS':
			state = update(state, {
				view: {
					overview: {
						orderDetails: {
							fields: {
								[state.view.overview.orderDetails.fields.findIndex(
									(fields) => fields.name === action.payload.name
								)]: {
									options: {
										$set: action.payload.json
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'TOGGLE_LOADING_FIELD':
			state = update(state, {
				view: {
					overview: {
						orderDetails: {
							fields: {
								[state.view.overview.orderDetails.fields.findIndex(
									(fields) => fields.name === action.payload.name
								)]: {
									inputLoading: {
										$set: action.payload.value
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'UPDATE_EMPLOYEE_OPTIONS':
			state = update(state, {
				view: {
					overview: {
						orderDetails: {
							fields: {
								[state.view.overview.orderDetails.fields.findIndex(
									(fields) => fields.name === action.payload.name
								)]: {
									options: {
										$set: action.payload.value
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'UPDATE_EMPLOYEE_VALUE':
			state = update(state, {
				view: {
					overview: {
						orderDetails: {
							fields: {
								[state.view.overview.orderDetails.fields.findIndex(
									(fields) => fields.name === action.payload.name
								)]: {
									value: {
										$set: action.payload.value
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'UPDATE_EMPLOYEE_LOADING':
			state = update(state, {
				view: {
					overview: {
						orderDetails: {
							fields: {
								[state.view.overview.orderDetails.fields.findIndex(
									(fields) => fields.name === action.payload.name
								)]: {
									loading: {
										$set: action.payload.value
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'UPDATE_SELECTED_INFO':
			state = update(state, {
				view: {
					overview: {
						orderDetails: {
							fields: {
								[state.view.overview.orderDetails.fields.findIndex(
									(fields) => fields.name === action.payload.name
								)]: {
									value: {
										$set: action.payload.value
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'WORK_SOW_LOADING_TOGGLE':
			state = update(state, {
				view: {
					overview: {
						sow: {
							loading: {
								$set: action.payload.value
							}
						}
					}
				}
			});
			break;

		case 'SET_WORK_SOW':
			state = update(state, {
				view: {
					overview: {
						sow: {
							value: {
								$set: action.payload.json.scopeOfWork
							}
						}
					},
					record: {
						scopeOfWork: {
							$set: action.payload.json.scopeOfWork
						}
					}
				}
			});
			break;

		case 'WORK_ORDERS_LOADING_TOGGLE':
			state = update(state, {
				view: {
					orders: {
						loading: {
							$set: action.payload.value
						}
					}
				}
			});
			break;

		case 'SET_WORK_ORDERS':
			state = update(state, {
				view: {
					orders: {
						orders: {
							$set: action.payload.json
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_TOGGLE_AASDI_MODAL':
			state = update(state, {
				view: {
					manageAASDI: {
						modal: {
							toggle: {
								$set: action.payload.value
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_TOGGLE_AASDI_DETAIL_MODAL':
			state = update(state, {
				toggleDetailModal: {
					$set: action.payload.value
				}
			});
			break;

		case 'WORK_VIEW_AASDI_LOADING':
			state = update(state, {
				view: {
					manageAASDI: {
						modal: {
							search: {
								loading: {
									$set: action.payload.value
								}
							}
						}
					}
				}
			});
			break;

		case 'SET_WORK_VIEW_SEARCH_AASDI':
			state = update(state, {
				view: {
					manageAASDI: {
						modal: {
							search: {
								results: {
									$set: action.payload.json
								}
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_AASDI_NAME_SEARCH':
			state = update(state, {
				view: {
					manageAASDI: {
						modal: {
							search: {
								name: {
									$set: action.payload.value
								}
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_AASDI_RADIUS':
			state = update(state, {
				view: {
					manageAASDI: {
						modal: {
							search: {
								radius: {
									$set: action.payload.value
								}
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_AASDI_ZIP':
			state = update(state, {
				view: {
					manageAASDI: {
						modal: {
							search: {
								zip: {
									$set: action.payload.value
								}
							}
						}
					}
				}
			});
			break;

		case 'SET_WORK_VIEW_SEARCH_AASDI_ZIP_ERROR':
			state = update(state, {
				view: {
					manageAASDI: {
						modal: {
							search: {
								zipError: {
									$set: action.payload.value
								}
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_BILLING_CREATE_MODAL_UPDATE_LOADER':
			state = update(state, {
				view: {
					BillingCenter: {
						projectCostingSpreadsheetModal: {
							fields: {
								[action.payload.fieldIndex]: {
									loading: {
										$set: action.payload.loading
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_BILLING_CREATE_MODAL_UPDATE_OPTIONS':
			state = update(state, {
				view: {
					BillingCenter: {
						projectCostingSpreadsheetModal: {
							fields: {
								[action.payload.fieldIndex]: {
									options: {
										$set: action.payload.json
									}
								}
							}
						}
					}
				}
			});
			break;

		/* NEW */
		case 'WORK_VIEW_PROJECT_SPREADSHEET_MODAL_GRID_GET_SKU_LOADED':
			state = update(state, {
				view: {
					BillingCenter: {
						projectCostingSpreadsheetModal: {
							skuLoaded: {
								$set: action.payload.skuLoaded
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_PROJECT_SPREADSHEET_MODAL_GRID_GET_SKUS':
			state = update(state, {
				view: {
					BillingCenter: {
						projectCostingSpreadsheetModal: {
							SKUs: {
								$set: action.payload.skuArray
							},
							skuLoaded: {
								$set: action.payload.skuLoaded
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_INVOICE_REMOVE_ROW':
			state = update(state, {
				view: {
					BillingCenter: {
						invoiceRowData: {
							$splice: [[action.payload.rowIndex, 1]]
						}
					}
				}
			});
			break;

		case 'UPDATE_AASDI_INFO_SECTION':
			state = update(state, {
				view: {
					manageAASDI: {
						infoSection: {
							[action.payload.index]: {
								sections: {
									[action.payload.sectionIndex]: {
										fields: {
											[action.payload.fieldIndex]: {
												value: {
													$set: action.payload.value
												},
												saved: {
													$set: false
												}
											}
										}
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'SET_TRIP_CONTACT':
			state = update(state, {
				view: {
					manageAASDI: {
						contactObject: {
							name: {
								$set:
									action.payload.json.firstName && action.payload.json.lastName
										? `${action.payload.json.firstName} ${action.payload.json.lastName}`
										: 'No Contact'
							},
							title: {
								$set: action.payload.json.title
							},
							phone: {
								$set: action.payload.json.phone
							},
							email: {
								$set: action.payload.json.email
							},
							actualDate: {
								$set: action.payload.json.actualDate ? action.payload.json.actualDate : ''
							},
							requestedDate: {
								$set: action.payload.json.requestedDate ? action.payload.json.requestedDate : ''
							}
						},
						infoSection: {
							[state.view.manageAASDI.infoSection.findIndex((side) => side.side === 'left')]: {
								sections: {
									[state.view.manageAASDI.infoSection[
										state.view.manageAASDI.infoSection.findIndex((side) => side.side === 'left')
									].sections.findIndex((fields) => fields.name === 'Basic Info')]: {
										fields: {
											2: {
												value: {
													$set: action.payload.json.requestedDate
														? action.payload.json.requestedDate
														: ''
												}
											},
											3: {
												value: {
													$set: action.payload.json.actualDate
														? action.payload.json.actualDate
														: ''
												}
											}
										}
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'SET_WORK_VIEW_AASDI_SOW':
			state = update(state, {
				view: {
					manageAASDI: {
						infoSection: {
							[state.view.manageAASDI.infoSection.findIndex((side) => side.side === 'right')]: {
								sections: {
									[state.view.manageAASDI.infoSection[
										state.view.manageAASDI.infoSection.findIndex((side) => side.side === 'right')
									].sections.findIndex((fields) => fields.name === 'AASDI SOW')]: {
										fields: {
											0: {
												value: {
													$set: action.payload.json.sowText
												}
											}
										}
									},
									[state.view.manageAASDI.infoSection[
										state.view.manageAASDI.infoSection.findIndex((side) => side.side === 'right')
									].sections.findIndex((fields) => fields.name === 'Special Instructions')]: {
										fields: {
											0: {
												value: {
													$set: action.payload.json.specialText
												}
											}
										}
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'SET_SELECTED_TRIP_ID':
			state = update(state, {
				view: {
					manageAASDI: {
						selectedTripId: {
							$set: action.payload.value
						}
					}
				}
			});
			break;

		case 'TOGGLE_NO_TRIP':
			state = update(state, {
				view: {
					manageAASDI: {
						noTrip: {
							$set: action.payload.value
						}
					}
				}
			});
			break;

		case 'SET_TRIP_LOADING_TOGGLE':
			state = update(state, {
				view: {
					manageAASDI: {
						infoSection: {
							[action.payload.index]: {
								sections: {
									[action.payload.sectionIndex]: {
										loading: {
											$set: action.payload.value
										}
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'SET_TRIP_FIELD_LOADING_TOGGLE':
			state = update(state, {
				view: {
					manageAASDI: {
						infoSection: {
							[action.payload.index]: {
								sections: {
									[action.payload.sectionIndex]: {
										fields: {
											[action.payload.fieldIndex]: {
												loading: {
													$set: action.payload.value
												}
											}
										}
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'UPDATE_WORK_VIEW_AASDI_VALUE':
			state = update(state, {
				view: {
					manageAASDI: {
						infoSection: {
							[action.payload.index]: {
								sections: {
									[action.payload.sectionIndex]: {
										fields: {
											[action.payload.fieldIndex]: {
												value: {
													$set: action.payload.value
												}
											}
										}
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'SET_MANAGE_AASDI_UNSAVED':
			state = update(state, {
				view: {
					manageAASDI: {
						infoSection: {
							[action.payload.index]: {
								sections: {
									[action.payload.sectionIndex]: {
										fields: {
											[action.payload.fieldIndex]: {
												saved: {
													$set: action.payload.value
												}
											}
										}
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_CREATE_BILL_EMPTY_REQUIRED':
			action.payload.map(
				(object) =>
					(state = update(state, {
						view: {
							BillingCenter: {
								projectCostingSpreadsheetModal: {
									fields: {
										[object.fieldIndex]: {
											value: {
												$set: object.field.value
											},
											error: {
												$set: true
											}
										}
									}
								}
							}
						}
					}))
			);
			break;

		case 'WORK_VIEW_BILLING_CENTER_UPDATE_BILLING_CONTACT_OPTIONS':
			state = update(state, {
				view: {
					BillingCenter: {
						currentBillingContactOptions: {
							options: {
								$set: action.payload.json
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_BILLING_CENTER_UPDATE_BILLING_INVOICE_GRID':
			state = update(state, {
				view: {
					BillingCenter: {
						invoiceRowData: {
							[action.payload.currentSelectedInvoiceIndex]: {
								[action.payload.column]: {
									$set: action.payload.value
								}
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_BILLING_CENTER_UPDATE_BILLING_INVOICE_GRID_CONTACT_LOADING':
			state = update(state, {
				view: {
					BillingCenter: {
						currentBillingContactOptions: {
							loading: {
								$set: action.payload.value
							}
						}
					}
				}
			});
			break;

		case 'WORK_DETAILS_NAVBAR_LOADING':
			state = update(state, {
				view: {
					navbarLoading: {
						$set: action.payload.loading
					},
					activeStep: {$set: 'Overview'}
				}
			});
			break;

		case 'WORK_VIEW_ACTION_BILL_MODAL_TOGGLE':
			state = update(state, {
				view: {
					BillingCenter: {
						actionBillModal: {
							actionBillModalToggle: {
								$set: action.payload.toggle
							},
							place: {
								$set: action.payload.place
							},
							data: {
								$set: action.payload.data
							},
							rowIndex: {
								$set: action.payload.rowIndex
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_ACTION_BILL_MODAL_LOADING':
			state = update(state, {
				view: {
					BillingCenter: {
						actionBillModal: {
							loading: {
								$set: action.loading
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_BILLING_CENTER_SET_BILL':
			state = update(state, {
				view: {
					BillingCenter: {
						invoiceRowData: {
							[action.payload.rowIndex]: {
								$set: action.payload.response
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_BILLING_CENTER_SET_BILLS':
			state = update(state, {
				view: {
					BillingCenter: {
						invoiceRowData: {
							$set: action.payload.invoiceRowData
						},
						hasPendingInvoices: {
							$set: action.payload.hasPendingInvoices
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_BILLING_CENTER_SUBMIT_BILL_LOADING':
			state = update(state, {
				view: {
					BillingCenter: {
						submitBillModal: {
							loading: {
								$set: action.loading
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_BILLING_CENTER_MODAL_LOADING':
			state = update(state, {
				view: {
					BillingCenter: {
						projectCostingSpreadsheetModal: {
							loading: {
								$set: action.payload
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_PROJECT_COST_SET_VENDOR_PURCHASE_ORDERS':
			state = update(state, {
				view: {
					ProjectCosting: {
						projectCostingWO: {
							purchaseOrdersGrid: {
								$set: action.payload
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_PROJECT_COST_VENDOR_PURCHASE_ORDERS_LOADING':
			state = update(state, {
				view: {
					ProjectCosting: {
						projectCostingWO: {
							purchaseOrdersLoading: {
								$set: action.payload.value
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_PROJECT_COSTING_SUMMARY_LOADING':
			state = update(state, {
				view: {
					ProjectCosting: {
						projectCostingPJ: {
							loading: {
								$set: action.payload.value
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_PROJECT_COSTING_SUMMARY':
			state = update(state, {
				view: {
					ProjectCosting: {
						projectCostingPJ: {
							summaryFields: {
								revenue: {
									0: {
										value: {
											$set: action.payload.revenue.estimatedRevenue
										}
									},
									1: {
										value: {
											$set: action.payload.revenue.estimatedCORevenue
										}
									},
									2: {
										value: {
											$set: action.payload.revenue.estimatedTotalRevenue
										}
									},
									3: {
										value: {
											$set: action.payload.revenue.actualCORevenue
										}
									},
									4: {
										value: {
											$set: action.payload.revenue.actualRevenue
										}
									}
								},
								cost: {
									0: {
										value: {
											$set: action.payload.cost.estimatedCost
										}
									},
									1: {
										value: {
											$set: action.payload.cost.estimatedCOCost
										}
									},
									2: {
										value: {
											$set: action.payload.cost.estimatedTotalCost
										}
									},
									3: {
										value: {
											$set: action.payload.cost.actualCOCost
										}
									},
									4: {
										value: {
											$set: action.payload.cost.actualCost
										}
									},
									5: {
										value: {
											$set: action.payload.cost.overUnderBudget
										}
									}
								},
								marginGP: {
									0: {
										value: {
											$set: action.payload.marginGP.estimatedMargin
										}
									},
									1: {
										value: {
											$set: action.payload.marginGP.estimatedGrossProfit
										}
									},
									2: {
										value: {
											$set: action.payload.marginGP.actualMargin
										}
									},
									3: {
										value: {
											$set: action.payload.marginGP.actualGrossProfit
										}
									},
									4: {
										value: {
											$set: action.payload.marginGP.remainingToBill
										}
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_BILLING_CENTER_INVOICE_LOADING':
			state = update(state, {
				view: {
					BillingCenter: {
						invoiceLoading: {
							$set: action.loading
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_ADD_ORDER_GROUP_MODAL_TOGGLE':
			state = update(state, {
				view: {
					BillingCenter: {
						orderGroup: {
							addOrderGroupModal: {
								toggle: {
									$set: action.payload.toggle
								},
								// Sets if adding new order group or editing existing one
								type: {
									$set: action.payload.type
								},
								// Resets modal data
								rowData: {
									$set: []
								},
								fields: {
									0: {
										value: {
											$set: ''
										}
									},
									1: {
										value: {
											$set: ''
										}
									},
									// Skipped quoted job # because componentDidMount runs before the toggle somehow
									3: {
										value: {
											$set: null
										},
										options: {
											$set: []
										}
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_ADD_ORDER_GROUP_SELECT_WO_MODAL_TOGGLE':
			state = update(state, {
				view: {
					BillingCenter: {
						orderGroup: {
							addOrderGroupModal: {
								selectWOModal: {
									toggle: {
										$set: action.payload.toggle
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_BILLING_ADD_ORDER_GROUP_MODAL_UPDATE_FIELD':
			let modalState = state.view.BillingCenter.orderGroup.addOrderGroupModal;
			let fieldIndex = modalState.fields.findIndex((fields) => fields.name === action.payload.name);
			if (fieldIndex === -1) {
				throw new Error(`Unknown field name: ${action.payload.name}`);
			}

			state = update(state, {
				view: {
					BillingCenter: {
						orderGroup: {
							addOrderGroupModal: {
								fields: {
									[fieldIndex]: {
										$merge: action.payload.value
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_BILLING_ADD_ORDER_GROUP_MODAL_UPDATE':
			state = update(state, {
				view: {
					BillingCenter: {
						orderGroup: {
							addOrderGroupModal: {
								fields: {
									[action.payload.fieldIndex]: {
										value: {
											$set: action.payload.value
										},
										error: {
											$set: action.payload.error
										}
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_BILLING_ADD_ORDER_GROUP_MODAL_UPDATE_OPTIONS':
			state = update(state, {
				view: {
					BillingCenter: {
						orderGroup: {
							addOrderGroupModal: {
								fields: {
									[action.payload.fieldIndex]: {
										options: {
											$set: action.payload.json
										}
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_BILLING_ADD_ORDER_GROUP_MODAL_UPDATE_LOADER':
			state = update(state, {
				view: {
					BillingCenter: {
						orderGroup: {
							addOrderGroupModal: {
								fields: {
									[action.payload.fieldIndex]: {
										loading: {
											$set: action.payload.loading
										}
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_ADD_ORDER_GROUP_SELECT_WO_MODAL_DATA':
			state = update(state, {
				view: {
					BillingCenter: {
						orderGroup: {
							addOrderGroupModal: {
								selectWOModal: {
									rowData: {
										$set: action.payload.json
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_ADD_ORDER_GROUP_LOADING':
			state = update(state, {
				view: {
					BillingCenter: {
						orderGroup: {
							addOrderGroupModal: {
								loading: {
									$set: action.payload.loading
								}
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_ADD_ORDER_GROUP_EMPTY_REQUIRED':
			action.payload.map(
				(object) =>
					(state = update(state, {
						view: {
							BillingCenter: {
								orderGroup: {
									addOrderGroupModal: {
										fields: {
											[object.fieldIndex]: {
												value: {
													$set: object.field.value
												},
												error: {
													$set: true
												}
											}
										}
									}
								}
							}
						}
					}))
			);
			break;

		case 'WORK_VIEW_ADD_ORDER_GROUP_ADD_SELECTED_ITEMS':
			state = update(state, {
				view: {
					BillingCenter: {
						orderGroup: {
							addOrderGroupModal: {
								rowData: {
									$push: action.payload
								}
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_ADD_ORDER_GROUP_REMOVE_AND_ADD_SELECT_ITEM':
			state = update(state, {
				view: {
					BillingCenter: {
						orderGroup: {
							addOrderGroupModal: {
								rowData: {
									$splice: [[action.payload.rowIndex, 1]]
								},
								// Add back to pool of selectable items
								selectWOModal: {
									rowData: {
										$push: [action.payload.rowData]
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_CANCEL_ORDER_GROUP':
			state = update(state, {
				view: {
					BillingCenter: {
						orderGroup: {
							projectGroups: {
								groups: {
									[action.payload.rowIndex]: {
										statusId: {
											$set: 5
										},
										status_Name: {
											$set: 'Canceled'
										}
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_ADD_ORDER_GROUP_REMOVE_ITEM':
			state = update(state, {
				view: {
					BillingCenter: {
						orderGroup: {
							addOrderGroupModal: {
								rowData: {
									$splice: [[action.payload.rowIndex, 1]]
								}
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_ADD_ORDER_GROUP_REMOVE_GROUP':
			state = update(state, {
				view: {
					BillingCenter: {
						orderGroup: {
							rowData: {
								$splice: [[action.payload.rowIndex, 1]]
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_ADD_ORDER_GROUP':
			state = update(state, {
				view: {
					BillingCenter: {
						orderGroup: {
							projectGroups: {
								groups: {
									$push: [action.payload.rowData]
								}
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_UPDATE_ORDER_GROUP':
			state = update(state, {
				view: {
					BillingCenter: {
						orderGroup: {
							rowData: {
								[action.payload.rowIndex]: {
									$set: action.payload.data
								}
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_ADD_ORDER_GROUP_MODAL_AUTOFILL':
			state = update(state, {
				view: {
					BillingCenter: {
						orderGroup: {
							addOrderGroupModal: {
								// Set modal data
								groupId: {
									$set: action.payload.rowData.id
								},
								orderGroupIndex: {
									$set: action.payload.rowIndex
								},
								rowData: {
									$set: action.payload.rowData.members
								},
								fields: {
									0: {
										value: {
											$set: action.payload.rowData.groupDescription
										}
									},
									1: {
										value: {
											$set: action.payload.rowData.purchaseOrderNumber
										}
									},
									// Skipped quoted job # because componentDidMount runs before the toggle somehow
									3: {
										value: {
											$set: action.payload.rowData.billingContact_Name
										}
										// options: {
										// 	$push: [
										// 		action.payload.rowData
										// 			.billingContact_Name
										// 	]
										// }
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_SUBMIT_ORDER_GROUP_MODAL_TOGGLE':
			state = update(state, {
				view: {
					BillingCenter: {
						orderGroup: {
							submitOrderGroupModal: {
								toggle: {
									$set: action.payload.toggle
								},
								data: {
									$set: action.payload.data
								},
								rowIndex: {
									$set: action.payload.rowIndex
								}
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_SUBMIT_ORDER_GROUP_MODAL_ADD_TO_GRID':
			state = update(state, {
				view: {
					BillingCenter: {
						orderGroup: {
							submitOrderGroupModal: {
								data: {
									members: {
										$push: [action.payload.data]
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_ORDER_GROUP_GRID_REMOVE_FROM_GRID':
			state = update(state, {
				view: {
					BillingCenter: {
						orderGroup: {
							projectGroups: {
								groups: {
									$splice: [
										[
											state.view.BillingCenter.orderGroup.projectGroups.groups.findIndex(
												(member) => member.id === action.payload.id
											),
											1
										]
									]
								}
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_SUBMIT_ORDER_GROUP_MODAL_LOADER':
			state = update(state, {
				view: {
					BillingCenter: {
						orderGroup: {
							submitOrderGroupModal: {
								loading: {
									$set: action.payload.value
								}
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_SUBMIT_REMOVE_GROUP_MODAL_TOGGLE':
			state = update(state, {
				view: {
					BillingCenter: {
						orderGroup: {
							removeGroupConfirmationModel: {
								toggle: {
									$set: action.payload.toggle
								},
								data: {
									$set: action.payload.data
								},
								rowIndex: {
									$set: action.payload.rowIndex
								}
							}
						}
					}
				}
			});
			break;

		case 'UPDATE_WORK_VIEW_SUBMIT_REMOVE_GROUP_MODAL_COMMENT':
			state = update(state, {
				view: {
					BillingCenter: {
						orderGroup: {
							removeGroupConfirmationModel: {
								comment: {
									$set: action.payload.value
								}
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_ORDER_GROUP_MODAL_SAVE_OLD_GROUP':
			state = update(state, {
				view: {
					BillingCenter: {
						orderGroup: {
							oldGroup: {
								$set: action.payload.oldGroup
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_PROJECT_SPREADSHEET_MODAL_UPDATE_LOADING':
			state = update(state, {
				view: {
					BillingCenter: {
						projectCostingSpreadsheetModal: {
							loading: {
								$set: action.payload
							}
						}
					}
				}
			});
			break;

		case 'CREATE_TRIP_MODAL_TOGGLE':
			state = update(state, {
				view: {
					manageAASDI: {
						createTrip: {
							modal: {
								open: {
									$set: action.payload.value
								},
								fields: {
									[state.view.manageAASDI.createTrip.modal.fields.findIndex(
										(fields) => fields.name === 'Trip Name'
									)]: {
										value: {
											$set: ''
										}
									},
									[state.view.manageAASDI.createTrip.modal.fields.findIndex(
										(fields) => fields.name === 'Contact'
									)]: {
										value: {
											$set: {}
										}
									},
									[state.view.manageAASDI.createTrip.modal.fields.findIndex(
										(fields) => fields.name === 'Requested Date'
									)]: {
										value: {
											$set: ''
										}
									},
									[state.view.manageAASDI.createTrip.modal.fields.findIndex(
										(fields) => fields.name === 'Actual Date'
									)]: {
										value: {
											$set: ''
										}
									},
									[state.view.manageAASDI.createTrip.modal.fields.findIndex(
										(fields) => fields.name === 'AASDI SOW'
									)]: {
										value: {
											$set: ''
										}
									},
									[state.view.manageAASDI.createTrip.modal.fields.findIndex(
										(fields) => fields.name === 'Special Instructions'
									)]: {
										value: {
											$set: ''
										}
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_TRIP_MODAL_LOADING':
			state = update(state, {
				view: {
					manageAASDI: {
						createTrip: {
							modal: {
								loading: {
									$set: action.payload.value
								}
							}
						}
					}
				}
			});
			break;

		case 'UPDATE_CREATE_TRIP':
			state = update(state, {
				view: {
					manageAASDI: {
						createTrip: {
							modal: {
								fields: {
									[action.payload.index]: {
										value: {
											$set: action.payload.value
										}
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'ADD_CREATE_TRIP_CONTACTS_OPTIONS':
			state = update(state, {
				view: {
					manageAASDI: {
						createTrip: {
							modal: {
								contactOptions: {
									$set: action.payload.value
								}
							}
						}
					}
				}
			});
			break;

		case 'TOGGLE_CREATE_TRIP_LOADING':
			state = update(state, {
				view: {
					manageAASDI: {
						createTrip: {
							modal: {
								fields: {
									[action.payload.index]: {
										loading: {
											$set: action.payload.value
										}
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_TRIP_TEXTAREA_SAVE':
			state = update(state, {
				view: {
					manageAASDI: {
						createTrip: {
							modal: {
								fields: {
									[action.payload.index]: {
										saved: {
											$set: action.payload.value
										}
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'SET_TRIP_OPTIONS':
			state = update(state, {
				view: {
					manageAASDI: {
						tripOptions: {
							$set: action.payload.trips
						}
					}
				}
			});
			break;

		case 'SET_TRIP_ISCOMPLETED':
			state = update(state, {
				view: {
					manageAASDI: {
						tripOptions: {
							[action.payload.index]: {
								isCompleted: {
									$set: action.payload.isCompleted
								}
							}
						}
					}
				}
			});
			break;

		case 'CLEAR_WORK_VIEW_CONTACT_OPTIONS':
			state = update(state, {
				view: {
					manageAASDI: {
						createTrip: {
							modal: {
								contactOptions: {
									$set: []
								}
							}
						}
					}
				}
			});
			break;

		case 'CLEAR_TRIP_OPTIONS':
			state = update(state, {
				view: {
					manageAASDI: {
						tripOptions: {
							$set: []
						}
					}
				}
			});
			break;

		case 'CREATE_TRIP_NO_NAME_CHECK':
			state = update(state, {
				view: {
					manageAASDI: {
						createTrip: {
							modal: {
								fields: {
									0: {
										error: {
											$set: action.payload.value
										}
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'ADD_TRIP_CONTACTS_OPTION':
			state = update(state, {
				view: {
					manageAASDI: {
						tripOptions: {
							$push: [action.payload.object]
						}
					}
				}
			});
			break;

		case 'REMOVE_TRIP_CONTACTS_OPTION':
			state = update(state, {
				view: {
					manageAASDI: {
						tripOptions: {
							$set: action.payload.trips
						}
					}
				}
			});
			break;

		case 'SET_TRIP_ID_CHECK':
			state = update(state, {
				view: {
					manageAASDI: {
						tripIdCheck: {
							$set: action.payload.id
						}
					}
				}
			});
			break;

		case 'SET_TRIPS_DROPDOWN_SELECTED':
			state = update(state, {
				view: {
					manageAASDI: {
						infoSection: {
							0: {
								sections: {
									0: {
										fields: {
											1: {
												value: {
													$set: action.payload.value
												}
											}
										}
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'WORK_RECORD_VIEW_SET_PROGRESS':
			state = update(state, {
				view: {
					overview: {
						progressStep: {
							activeStep: {
								$set: action.payload.value
							}
						}
					}
				}
			});
			break;

		case 'ADD_ORDER_GROUP_MODAL_SET_WORK_ORDERS':
			state = update(state, {
				view: {
					BillingCenter: {
						orderGroup: {
							addOrderGroupModal: {
								selectWOModal: {
									rowData: {
										$set: action.payload.json
									}
								}
							}
						}
					}
				}
			});
			break;
		case 'WORK_RECORD_FINANCIALS_GROUPS':
			state = update(state, {
				view: {
					BillingCenter: {
						orderGroup: {
							projectGroups: {
								$set: action.payload.response
							}
						}
					}
				}
			});
			break;

		case 'WORK_VIEW_SET_CHANGE_ORDERS_GRID':
			state = update(state, {
				view: {
					ProjectCosting: {
						ChangeOrders: {
							grid: {
								data: {
									$set: action.payload
								}
							}
						}
					}
				}
			});
			break;

		case GET_WORK_VIEW_AASDI_STATUS_SUCCESS:
			state = update(state, {
				view: {
					eventLog: {
						aasdiStatusLoading: {
							$set: false
						},
						aasdiStatusRecords: {
							$set: action.payload.aasdiStatusRecords
						}
					}
				}
			});
			break;

		case GET_WORK_VIEW_AASDI_STATUS_FAILURE:
			state = update(state, {
				view: {
					eventLog: {
						aasdiStatusLoading: {
							$set: false
						},
						aasdiStatusError: {
							$set: true
						}
					}
				}
			});
			break;

		case WORK_VIEW_PUSH_INSERTED_BILLABLE_ITEM:
			return {
				...state,
				view: {
					...state.view,
					BillingCenter: {
						...state.view.BillingCenter,
						projectCostingSpreadsheetModal: {
							...state.view.BillingCenter.projectCostingSpreadsheetModal,
							updatedBillableItems: pushOrUpdate(
								state.view.BillingCenter.projectCostingSpreadsheetModal.updatedBillableItems,
								action.payload.billableItem
							)
						}
					}
				}
			};

		case WORK_VIEW_PUSH_UPDATED_BILLABLE_ITEM:
			return {
				...state,
				view: {
					...state.view,
					BillingCenter: {
						...state.view.BillingCenter,
						projectCostingSpreadsheetModal: {
							...state.view.BillingCenter.projectCostingSpreadsheetModal,
							updatedBillableItems: pushOrUpdate(
								state.view.BillingCenter.projectCostingSpreadsheetModal.updatedBillableItems,
								getBillableItem(state, action.payload.billData, action.payload.index)
							)
						}
					}
				}
			};
		case WORK_VIEW_PUSH_DELETED_BILLABLE_ITEM:
			return {
				...state,
				view: {
					...state.view,
					BillingCenter: {
						...state.view.BillingCenter,
						projectCostingSpreadsheetModal: {
							...state.view.BillingCenter.projectCostingSpreadsheetModal,
							deletedBillableItems: pushOrUpdate(
								state.view.BillingCenter.projectCostingSpreadsheetModal.deletedBillableItems,
								getBillableItem(state, {}, action.payload.rowIndex)
							)
						}
					}
				}
			};

		case WORK_VIEW_CLEAR_INSERTED_BILLABLE_ITEM_DATA:
			return {
				...state,
				view: {
					...state.view,
					BillingCenter: {
						...state.view.BillingCenter,
						projectCostingSpreadsheetModal: {
							...state.view.BillingCenter.projectCostingSpreadsheetModal,
							updatedBillableItems: [],
							deletedBillableItems: []
						}
					}
				}
			};

		default:
			break;
	}
	return state;
};
