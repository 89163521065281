import React, {Component} from 'react';
import {Segment, Grid, Header, Input, Dropdown, Label, Icon} from 'semantic-ui-react';
import {DebounceInput} from 'react-debounce-input';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import {debounce, debounceEventHandler} from '../../../../../../../../../../lib/old/debounce.js';
import {validateGoogleDriveLink} from '../../../../../../../../../../lib/old/validation.js';
import * as actions from '../../../../services/workRecordActions.js';

import InfoSection from './components/infoSection.js';
import {MomentDatePicker} from '../../../../../../../../../../components/inputs/DatePicker.jsx';

class OrderDetailsSection extends Component {
	state = {
		startDate: moment(new Date()).format('MM-DD-YYYY'),
		endDate: moment(new Date()).format('MM-DD-YYYY'),
		googleDriveFormatError: false
	};

	componentDidUpdate(oldProps) {
		const newProps = this.props;

		newProps.orderDetails.fields.forEach((item, index) => {
			if (
				item.name === 'Start Date' &&
				oldProps.orderDetails.fields[index].value !== newProps.orderDetails.fields[index].value
			) {
				if (moment(newProps.orderDetails.fields[index].value).isValid())
					this.setState({startDate: moment(item.value)});
			}
			if (
				item.name === 'End Date' &&
				oldProps.orderDetails.fields[index].value !== newProps.orderDetails.fields[index].value
			) {
				if (moment(newProps.orderDetails.fields[index].value).isValid())
					this.setState({endDate: moment(item.value)});
			}
		});
	}

	inputValidation = (item, desynced) => {
		if (item.tech) {
			let techs = item.value ? item.value.split(',') : ['No Technology'];

			return (
				<div>
					{techs.map((tech, index) => {
						return (
							<Label size="small" style={{marginTop: '.5vh'}} key={`${index}`}>
								{tech}
							</Label>
						);
					})}
				</div>
			);
		}

		if (item.name === 'Start Date') {
			return (
				<div>
					<Input fluid className="hiddenInput">
						<MomentDatePicker
							selected={
								this.state.startDate !== 'No start date defined'
									? moment(this.state.startDate)
									: moment(new Date()).format('MM-DD-YYYY')
							}
							placeholderText="Select a date"
							onChange={(date) => {
								if (moment(date.format('MM-DD-YYYY')).isSameOrBefore(this.state.endDate)) {
									this.setState({
										startDate: moment(date.format('MM-DD-YYYY'))
									});
									this.props.updateWorkField(
										this.props.record.opportunityData
											? this.props.record.opportunityData.id
											: this.props.record.id,
										'startDate',
										date ? date.format('MM-DD-YYYY').toString() : null,
										null,
										Boolean(this.props.record.opportunityData)
									);
								}
							}}
						/>
					</Input>
				</div>
			);
		}

		if (item.name === 'End Date') {
			return (
				<div>
					<Input fluid className="hiddenInput">
						<MomentDatePicker
							selected={
								this.state.endDate !== 'No end date defined'
									? moment(this.state.endDate)
									: moment(new Date()).format('MM-DD-YYYY')
							}
							placeholderText="Select a date"
							onChange={(date) => {
								if (moment(date.format('MM-DD-YYYY')).isSameOrAfter(this.state.startDate)) {
									this.setState({
										endDate: moment(date.format('MM-DD-YYYY'))
									});
									this.props.updateWorkField(
										this.props.record.opportunityData
											? this.props.record.opportunityData.id
											: this.props.record.id,
										'endDate',
										date ? date.format('MM-DD-YYYY').toString() : null,
										null,
										Boolean(this.props.record.opportunityData)
									);
								}
							}}
						/>
					</Input>
				</div>
			);
		}

		if (item.employee) {
			return (
				<div>
					<Dropdown
						fluid
						className="hiddenInput"
						search
						onSearchChange={
							desynced
								? null
								: (e, data) => {
										this.props.employeeUpdateValue(data.searchQuery, item.name);
										this.props.employeeSearch(data.searchQuery, item.name);
								  }
						}
						onChange={
							desynced
								? null
								: (e, data) => {
										let fieldText = data.options.find((option) => option.value === data.value).text;
										this.props.employeeUpdateValue(fieldText, item.name);
										this.props.updateWorkField(this.props.record.id, item.name, data.value);
								  }
						}
						loading={item.loading}
						text={item.value}
						options={item.options}
						disabled={Boolean(desynced || item.disabled === true)}
					/>
				</div>
			);
		}

		if (item.masterClient) {
			return (
				<div>
					<Dropdown
						fluid
						className="hiddenInput"
						search
						onSearchChange={debounceEventHandler(
							debounce((e) => {
								this.props.getDropdownOptions(item.name, null, e.target.value);
							}, 600)
						)}
						onChange={
							desynced
								? null
								: (e, data) => {
										this.props.employeeUpdateValue(
											data.options.find((option) => option.value === data.value).text,
											item.name
										);
										this.props.updateWorkField(this.props.record.id, item.name, data.value);
								  }
						}
						loading={item.inputLoading}
						text={item.value}
						options={item.options}
						disabled={Boolean(desynced || item.disabled === true)}
					/>
				</div>
			);
		}

		if (item.dropdown) {
			if (item.name === 'Service Type') {
				if (item.value === 4) {
					return (
						<div>
							<Dropdown
								fluid
								className="hiddenInput"
								onChange={(e, data) => {
									console.log(data);
									this.props.updateWorkField(this.props.record.id, item.name, data.value);
								}}
								value={item.value}
								options={item.options}
								disabled
							/>
							<Dropdown
								fluid
								className="hiddenInput"
								value={this.props.subType}
								onChange={(e, data) => {
									this.props.updateWorkField(this.props.record.id, 'subType', data.value);
								}}
								options={[
									{
										key: 1,
										text: 'Move, Add, Change',
										value: 1
									},
									{
										key: 2,
										text: 'Staging / Configuration',
										value: 2
									},
									{
										key: 3,
										text: 'Survey',
										value: 3
									},
									{
										key: 4,
										text: 'Repair',
										value: 4
									},
									{
										key: 5,
										text: 'Annuity',
										value: 5
									},
									{
										key: 6,
										text: 'Small Project',
										value: 6
									}
								]}
								disabled
							/>
						</div>
					);
				} else {
					return (
						<div>
							<Dropdown
								fluid
								className="hiddenInput"
								onChange={
									desynced
										? (e, data) => {
												this.props.updateWorkField(this.props.record.id, item.name, data.value);
										  }
										: null
								}
								value={item.value}
								options={item.options}
								disabled
							/>
						</div>
					);
				}
			} else if (item.name === 'Service SubType') {
				return null;
			} else if (item.name === 'Work Category') {
				return (
					<Dropdown
						fluid
						className="hiddenInput"
						onChange={(e, data) => {
							this.props.updateWorkField(this.props.record.id, item.name, data.value);
						}}
						value={item.value?.toLowerCase()}
						options={item.options}
						disabled
					/>
				);
			} else {
				return (
					<Dropdown
						fluid
						className="hiddenInput"
						onChange={(e, data) => {
							this.props.updateWorkField(this.props.record.id, item.name, data.value);
						}}
						value={item.value}
						options={item.options}
					/>
				);
			}
		} else if (item.name === 'Client') {
			const spectrumCode = this.props.orderDetails.fields[8].value;
			const clientName = `${item.value} ${spectrumCode ? `(${spectrumCode})` : ''}`;

			return (
				<DebounceInput
					element={Input}
					transparent
					fluid
					onChange={(e) => {
						this.props.updateWorkField(this.props.record.id, item.name, e.target.value);
					}}
					debounceTimeout={600}
					value={clientName}
					disabled
				/>
			);
		} else if (item.googleDrive) {
			const link = item.value;

			if (!link) {
				return (
					<div>
						<Icon size="large" name={item.iconName} />
						<span>No Link Selected</span>
					</div>
				);
			}
			return (
				<div>
					<a href={link} alt="Google drive Link" target="_blank">
						<Icon
							link
							size="large"
							name={item.iconName}
							value={item.name === 'AASDI' ? this.props.aasdiName : item.value}
							disabled={item.name === 'AASDI'}
							loading={item.name === 'AASDI' ? this.props.aasdiLoading : false}
						/>
					</a>
					<DebounceInput
						element={Input}
						transparent
						fluid
						onChange={(e) => {
							if (validateGoogleDriveLink(e.target.value)) {
								this.setState({
									googleDriveFormatError: false
								});
								this.props.updateWorkField(this.props.record.id, item.name, e.target.value);
							} else {
								this.setState({
									googleDriveFormatError: true
								});
							}
						}}
						error={this.state.googleDriveFormatError}
						debounceTimeout={600}
						value={link}
					/>
					{this.state.googleDriveFormatError ? (
						<span>
							Invalid link. examples of correct links: https:\/\/drive.google.com/open?id=FILE_ID, or
							https:\/\/docs.google.com/document/d/FILE_ID/edit?usp=sharing
						</span>
					) : null}
				</div>
			);
		} else if (item.disabled) {
			return <p style={{color: '#646564'}}>{item.value}</p>;
		} else if (item.name === 'Quoted Job Number') {
			let errorElem = null;
			if (item.error) {
				errorElem = (
					<Label color="red" pointing="above">
						{item.error.message}
					</Label>
				);
			}
			return (
				<React.Fragment>
					<DebounceInput
						element={Input}
						transparent
						fluid
						debounceTimeout={600}
						value={item.value}
						loading={item.loading}
						disabled={this.props.isGroupBillableProject}
						onChange={(e) => {
							this.props.updateWorkField(this.props.record.id, item.name, e.target.value.toUpperCase());
						}}
					/>
					{errorElem}
				</React.Fragment>
			);
		} else {
			return (
				<DebounceInput
					element={Input}
					fluid
					transparent
					className={
						item.name === 'AASDI' || item.name === 'ASD Job Number' || desynced
							? ''
							: item.name === 'Quoted Job Number'
							? ''
							: 'hiddenInput'
					}
					onChange={(e) => {
						this.props.updateWorkField(this.props.record.id, item.name, e.target.value);
					}}
					debounceTimeout={600}
					value={item.name === 'AASDI' ? this.props.aasdiName : item.value}
					disabled={item.name === 'AASDI' || item.name === 'ASD Job Number' || desynced}
					loading={item.name === 'AASDI' ? this.props.aasdiLoading : false}
				/>
			);
		}
	};

	render() {
		return (
			<Segment loading={this.props.orderDetails.loading} color={this.props.isDesynced ? 'yellow' : null}>
				<Label color={this.props.isDesynced ? 'yellow' : 'blue'} ribbon="left" style={{marginBottom: '2vh'}}>
					{this.props.isDesynced
						? `Order Details   * This record became desynced with Spectrum on 
						${moment(this.props.record.desyncs[0].desyncedOn).format('YYYY-MM-DD HH:MM')}.`
						: 'Order Details '}
				</Label>

				<Grid>
					<Grid.Row>
						{this.props.orderDetails.fields.map((item, index) => {
							if (item.info === true) {
								if (item.name === 'Spectrum Code') {
									return null;
								}
								return (
									<Grid.Column key={index} style={{margin: '1vh'}} width={item.columnWidth}>
										<Header as="h5">
											<Header.Content>{item.name}</Header.Content>
										</Header>

										{this.inputValidation(item, this.props.isDesynced)}
									</Grid.Column>
								);
							} else {
								if (this.props.record.isGroupBillable === false) {
									return <InfoSection item={item} />;
								} else if (item.name === 'Contact') {
									return <InfoSection item={item} />;
								}
								return null;
							}
						})}
					</Grid.Row>
				</Grid>
			</Segment>
		);
	}
}

OrderDetailsSection.propTypes = {
	subType: PropTypes.number,
	orderDetails: PropTypes.object,
	record: PropTypes.object,
	aasdiLoading: PropTypes.bool,
	aasdiName: PropTypes.string,
	isDesynced: PropTypes.bool,
	desyncedDate: PropTypes.string,
	employeeUpdateValue: PropTypes.func,
	employeeSearch: PropTypes.func,
	updateWorkField: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		record: state.work.workRecord.view.record,
		isGroupBillableProject: state.work.workRecord.view.record && state.work.workRecord.view.record.isGroupBillable,
		aasdiLoading: state.work.workRecord.view.manageAASDI.loading,
		subType: state.work.workRecord.view.overview.orderDetails.serviceSubtypeValue,
		aasdiName: state.work.workRecord.view.manageAASDI.aasdis[0]
			? state.work.workRecord.view.manageAASDI.aasdis[0].name
			: 'None',
		isDesynced: state.work.workRecord.view.record.desynced,
		desyncedDate: state.work.workRecord.view.record.lastSyncError
	};
};

export default connect(mapStateToProps, actions)(OrderDetailsSection);
