import React from 'react';
import moment from 'moment';
import PDFRenderer from '@react-pdf/renderer';
import { generatePDFFromHTML } from '../../../../../../../../../../../components/pdf/generatePDFFromHTML.js';
import Styled from '../../../../../../../../../../../components/pdf/pdfStyleWrapper.js'

const Sow = props => {
	const sow = props.sow;

	return (
		<View style={{ marginTop: 20 }}>
			<TextRegular bold>Description of Work:</TextRegular>
			<View>
				{sow ? generatePDFFromHTML(sow) : null}
			</View>
		</View>
	);
};


//-------//-------//-------//-------//-------//-------//-------//-------//
//-------//-------//-------//-------//-------//-------//-------//-------//
//-------//-------//-------//-------//-------//-------//-------//-------//
//-------//-  ALWAYS UPDATE lambda/jsx/pdfTemplate.jsx  -------//-------//
//-------//-------  when you update this file  -------//-------//-------//
//-------//-------//-------//-------//-------//-------//-------//-------//
//-------//-------//-------//-------//-------//-------//-------//-------//
//-------//-------//-------//-------//-------//-------//-------//-------//

const {Document, Page, Text, Image, View, StyleSheet} = PDFRenderer;

const TextBase = Styled(Text, {
	fontSize: '7.5pt',
	fontFamily: props => props.bold ? 'Helvetica-Bold' : 'Helvetica',
	textAlign: props => props.textAlign ? props.textAlign : ''
})

const TextSmall = Styled(TextBase, {
	fontSize: '7.5pt'
})

const TextRegular = Styled(TextBase, {
	fontSize: '9pt'
})
	
const FooterItem = Styled(View, {
	flex: props => props.flex ? props.flex : '1'
})

const Row = Styled(View, {
	flexDirection: 'row',
	flexWrap: 'wrap',
	display: 'flex',
	marginTop: props => props.marginTop ? props.marginTop : '0pt',
	justifyContent: props => props.justifyContent ? props.justifyContent : 'space-between'
})

const Logo = Styled(Image, {
	height:' 19.5pt',
	width: '60pt'
})

const Hr = Styled(View, {
	height: '.75pt',
	borderWidth: '.75pt',
	borderStyle: 'solid',
	borderColor: props => props.color ? props.color : 'black'
})
	
const HeaderTable = Styled(View, {
	display: 'table',
	width: 'auto'
})

const TR = Styled(View, {
	flexDirection: 'row',
	margin: 'auto'
})
	
const TD = Styled(View, {
	width: '50%'
})

const BillableItemsTable = Styled(View, {
	display: 'table',
	width: 'auto',
	margin: '5pt 0pt 5pt 0pt',
	padding: '0'
})
	
const BillableItemsRow = Styled(View, {
	flexDirection: 'row',
	margin: '0',
	borderStyle: 'solid',
	borderTop: '0.5pt',
	borderBottom: '0.5pt'
})

const BillableItemsCol = Styled(View, {
	width: '16.67%',
	margin: '0',
	borderStyle: 'solid',
	borderRight: '0.5pt',
	borderLeft: '0.5pt'
})
	
const BillableItemsHeaderTD = Styled(Text, {
	backgroundColor: '#bababa',
	height: 'auto',
	paddingTop: '5pt',
	paddingBottom: '5pt',
	fontSize: '6.75pt',
	textAlign: 'center'
})

const BillableItemsTD = Styled(Text, {
	padding: '3pt',
	fontSize: '6.75pt'
})
	

const styles = StyleSheet.create({
	body: {
		paddingTop: 20,
		paddingBottom: 50,
		paddingHorizontal: 35,
		flexDirection: 'row'
	},
	pageNumber: {
		position: 'absolute',
		fontSize: '9pt',
		bottom: 20,
		left: 0,
		right: 0,
		textAlign: 'center',
		color: 'grey'
	}
});

const Header = (props) => {
	const {headerData, invoiceRowData, items} = props;
	const {jobSite, soldTo} = headerData;
	const {freight} = invoiceRowData;
	const totalLaborResell = items ? items.reduce((acc, item) => acc + item.laborResell, 0) : null;
	const totalMaterialResell = items ? items.reduce((acc, item) => acc + item.materialResell, 0) : null;
	const salesTax = invoiceRowData.salesTax ? invoiceRowData.salesTax : 0;
	const totalAmount = totalLaborResell + totalMaterialResell + freight + salesTax;

	return (
		<View style={{width: '100%', paddingBottom: 10}}>
			<TextRegular bold textAlign={'center'}>
				Invoice
			</TextRegular>
			<View style={{marginTop: 10, marginBottom: 10}}>
				<Row>
					<View style={{width: '60%'}}>
						<Logo
							style={{marginBottom: 10}}
							src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJYAAAA1CAMAAACUV+KQAAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAE5QTFRFR3BMAEqTAEqTAEqTAEqTAEqTAEqTAEqTAEqTAEqTAEqTAEqTAEqTAEqTAEqTAEqTAEqTAEqTAEqTAEqTAEqTAEqTAEqTAEqTAEqTAEqTzAeFEgAAABl0Uk5TAA5iiPcE7pFQIKJ+MMyu4Jo8FG+31ibCG9sbjU0AAAN7SURBVFjD7VjZdqswDMQbGDCr2fz/P3rb5LZ4kWxDctqXzmMCQpZHo6Uo/vCzEEpS/UDrgVUoWEuV8AzNzBjD0h+cjI0VekSxnZu72NvD9mod54L0XdKtzrVCggdkZ14D79W3sb5ln8EaE/ES1LOhvAe23bwBzVfEyoKx2ah6iLs1l54B6l1xad6CUorn54qPaI2f7kUx+u9XLqu4eRfa72i1S5GIlgoZav9NBvM+POjUa8aKQSa4FbLZiS4z7wR9HLSfizqRiRR42Upnwt/qFt/ydItAfJZxr1/BkifdFfSuPv9vnNvtlv6Jxkcg749f+37xqTnleKVgiTkfWE+f9HyrZE09ZhsHLJRW+Smhm72IyY4YJ+kXEObws7iejN/uF/ljgImL8R3TpLP8nE/05L5fG8fEGoKjSRVYfpbzx2G671eLiDV4Bscr5avxA9rJ7syLnCvqX6N1OpHIkcXO/VlwSF3cOs6bOu0UYTxoQiwymMTr0qsKK3gi7WXDmHCM6BJKtz3WzqF87zzlnHHBjTomaPll7qZbzE89jWhxUKz5eGCC8/+oY/DPmumWnbMPM2KCy8/HbQd1k2uIt3JFGklHlk2U8g7fiZct7nEF0DXvQSWadrzttm2XV/juZYtPDhoEbNhw9vDY59YrfPcCyEWQ9b5jQ41e0x5rzJs81T1DbnM7FM6j8jrCVWDazGJdnca9qjnEI5koqFsDTQxA1Q8Yr/Mart7Rd6j5gnvazemd+Iz0k3Vk5Buu8f0z46wY9jm9k4YZX8aigDcQYjVwznUZhyIV6DtcUYE6sV3juz/I4lqsoVzHCSAcRvZ4r8gx3cxkZgfoE0XSRUxrMJBd4bu/UKJojSAr0KOM4JipdAeM1fAkEvD9oPIJ+8gL/YAEwAZIza3Pl0pNk6TtuAeloRN5fBcvTc4ryPjIzuZAvdLALDneXVkl5k3fK4XzvQT4rm66RbEeFsQQmQQakO/3dpBWa50R72XOXN6eyXZvASLB5hO5QB3p/kQHp4W4s4dk+esmvM0OKTC9tlxj6AY9iFRV5y9vR3SSzUE3ocf1ArXQ1JagQfXdae0z9v8SN2y7tFcyvbmYIltumb1m3Fn4KZfx5bAuTUtlxgTuy4BfBoRSaquTOJDDky88jV1Zy9HLu8KfAM73X8WI8/0XoYzJGop+GJ3BZ+Y/uPgHjlBDmvrMi0YAAAAASUVORK5CYII="
						/>
						<TextRegular bold>{headerData.type}</TextRegular>
						<TextRegular bold>{headerData.branch}</TextRegular>
					</View>
					<View style={{width: '40%'}} flex={'0 1 auto'}>
						<HeaderTable>
							<TR>
								<TD>
									<TextRegular bold>PAGE</TextRegular>
								</TD>
								<TD>
									<TextRegular>1</TextRegular>
								</TD>
							</TR>
							<TR>
								<TD>
									<TextRegular bold>INVOICE #</TextRegular>
								</TD>
								<TD>
									<TextRegular>{headerData.invoiceNumber}</TextRegular>
								</TD>
							</TR>
							<TR>
								<TD>
									<TextRegular bold>INVOICE DATE</TextRegular>
								</TD>
								<TD>
									<TextRegular>{headerData.invoiceDate}</TextRegular>
								</TD>
							</TR>
							<TR>
								<TD>
									<TextRegular bold>CUST. PO #</TextRegular>
								</TD>
								<TD>
									<TextRegular>{headerData.purchaseOrderNumber}</TextRegular>
								</TD>
							</TR>
							<TR>
								<TD>
									<TextRegular bold>TERMS</TextRegular>
								</TD>
								<TD>
									<TextRegular>{headerData.terms}</TextRegular>
								</TD>
							</TR>
							<TR>
								<TD>
									<TextRegular bold>TOTAL DUE</TextRegular>
								</TD>
								<TD>
									<TextRegular>$ {totalAmount.toFixed(2)}</TextRegular>
								</TD>
							</TR>
							<TR>
								<TD>
									<TextRegular bold>WORK ORDER #</TextRegular>
								</TD>
								<TD>
									<TextRegular>{headerData.WorkNumber}</TextRegular>
								</TD>
							</TR>
							<TR>
								<TD>
									<TextRegular bold>DUE DATE</TextRegular>
								</TD>
								<TD>
									<TextRegular>{headerData.dueDate}</TextRegular>
								</TD>
							</TR>
						</HeaderTable>
					</View>
				</Row>
			</View>
			<Row style={{marginTop: 20, marginBottom: 20}}>
				<View style={{width: '9%'}}>
					<TextRegular bold>Sold To:</TextRegular>
				</View>
				<View style={{width: '41%'}}>
					{soldTo.contactName ? <TextRegular>{soldTo.contactName}</TextRegular> : null}
					{soldTo.contactAddress ? <TextRegular>{soldTo.contactAddress}</TextRegular> : null}
					{soldTo.contactAddress2 ? <TextRegular>{soldTo.contactAddress2}</TextRegular> : null}
					{soldTo.contactCity || soldTo.contactState || soldTo.contactZip ? (
						<TextRegular>{`${soldTo.contactCity ? soldTo.contactCity + `, ` : null}${
							soldTo.contactState ? soldTo.contactState : null
						} ${soldTo.contactZip ? soldTo.contactZip : null}`}</TextRegular>
					) : null}
				</View>
				<View style={{width: '9%'}}>
					<TextRegular bold>Job Site:</TextRegular>
				</View>
				<View style={{width: '41%'}}>
					{jobSite.locationName ? <TextRegular>{jobSite.locationName}</TextRegular> : null}
					{jobSite.locationStoreNumber ? (
						<TextRegular> {`Site #: ${jobSite.locationStoreNumber}`}</TextRegular>
					) : null}
					{jobSite.locationAddress ? <TextRegular>{`${jobSite.locationAddress}`}</TextRegular> : null}
					{jobSite.locationAddress2 ? <TextRegular>{`${jobSite.locationAddress2}`}</TextRegular> : null}
					{jobSite.locationCity || jobSite.locationState || jobSite.locationZip ? (
						<TextRegular>{`${jobSite.locationCity ? jobSite.locationCity : null}, ${
							jobSite.locationState ? jobSite.locationState : null
						} ${jobSite.locationZip ? jobSite.locationZip : null}`}</TextRegular>
					) : null}
				</View>
			</Row>
		</View>
	);
};

const Table = (props) => {
	const {headers, items} = props;
	return (
		<BillableItemsTable>
			<BillableItemsRow wrap={false}>
				{headers.map((header, index) => (
					<BillableItemsCol style={{width: header.width}} key={index}>
						<BillableItemsHeaderTD bold>{header.name}</BillableItemsHeaderTD>
					</BillableItemsCol>
				))}
			</BillableItemsRow>
			{items.map((item, itemIndex) => (
				<BillableItemsRow wrap={false} key={itemIndex}>
					{headers.map((header, headerIndex) => (
						<BillableItemsCol style={{width: header.width, textAlign: header.textAlign}} key={headerIndex}>
							<BillableItemsTD>
								{' '}
								{header.type === 'money' ? '$ ' : ''}{' '}
								{header.type === 'money' && item[header.field]
									? item[header.field].toFixed(2)
									: header.type === 'money'
									? '0.00'
									: item[header.field]}
							</BillableItemsTD>
						</BillableItemsCol>
					))}
				</BillableItemsRow>
			))}
		</BillableItemsTable>
	);
};

const SKUs = (props) => {
	const {items} = props;
	const headers = [
		{
			name: 'Quantity',
			field: 'quantity',
			width: '10%',
			textAlign: 'center'
		},
		{
			name: 'Description',
			field: 'description',
			width: '22%',
			textAlign: 'left'
		},
		{
			name: 'Part #',
			field: 'partNumber',
			width: '20%',
			textAlign: 'left'
		},
		{
			name: 'Extended Labor',
			field: 'laborResell',
			type: 'money',
			width: '16%',
			textAlign: 'right'
		},
		{
			name: 'Extended Material',
			field: 'materialResell',
			type: 'money',
			width: '16%',
			textAlign: 'right'
		},
		{
			name: 'Extended Total',
			field: 'totalResell',
			type: 'money',
			width: '16%',
			textAlign: 'right'
		}
	];
	return <Table headers={headers} items={items} />;
};

const Foot = (props) => {
	const {invoiceRowData, items} = props;
	const totalLaborResell = items.reduce((acc, item) => acc + item.laborResell, 0);
	const totalMaterialResell = items.reduce((acc, item) => acc + item.materialResell, 0);
	const freight = invoiceRowData.freight ? invoiceRowData.freight : 0;
	const salesTax = invoiceRowData.salesTax ? invoiceRowData.salesTax : 0;
	const totalAmount = totalLaborResell + totalMaterialResell + freight + salesTax;

	return (
		<View style={{width: '100%', marginTop: 15}}>
			<Row justifyContent={'flex-end'}>
				<FooterItem flex={'0 0 60pt'}>
					<TextRegular>Labor:</TextRegular>
					<TextRegular>Material:</TextRegular>
					<TextRegular>Freight:</TextRegular>
					<TextRegular>Sales Tax:</TextRegular>
					<TextRegular> </TextRegular>
					<Hr color={'white'} />
					<TextRegular> </TextRegular>
					<TextRegular bold>Total Amount:</TextRegular>
				</FooterItem>
				<FooterItem flex={'0 0 60pt'}>
					<TextRegular textAlign={'right'}>
						$ {totalLaborResell ? totalLaborResell.toFixed(2) : '0.00'}
					</TextRegular>
					<TextRegular textAlign={'right'}>
						$ {totalMaterialResell ? totalMaterialResell.toFixed(2) : '0.00'}
					</TextRegular>
					<TextRegular textAlign={'right'}>$ {freight ? freight.toFixed(2) : '0.00'}</TextRegular>
					<TextRegular textAlign={'right'}>$ {salesTax ? salesTax.toFixed(2) : '0.00'}</TextRegular>
					<TextRegular> </TextRegular>
					<Hr />
					<TextRegular> </TextRegular>
					<TextRegular textAlign={'right'} bold>
						$ {totalAmount.toFixed(2)}
					</TextRegular>
				</FooterItem>
			</Row>
		</View>
	);
};

const branchType = 'Automated Systems Design, Inc.';
const branchAddress = '1111 Alderman Drive\nSuite 300\nAlpharetta, GA 30005';

export default (props) => {
	const invoiceRowData = props;
	const {loading, billClient, billContact, workRecord, items, billingSow} = props;

	let dueDateAddition;
	let termDesc;
	switch (invoiceRowData.spectrumTermsCode) {
		case '0':
			dueDateAddition = 0;
			termDesc = 'Due/Receipt';
			break;
		case '3':
			dueDateAddition = 30;
			termDesc = 'Net 30';
			break;
		case '4':
			dueDateAddition = 45;
			termDesc = 'Net 45';
			break;
		case '6':
			dueDateAddition = 60;
			termDesc = 'Net 60';
			break;
		case '7':
			dueDateAddition = 75;
			termDesc = 'Net 75';
			break;
		case '9':
			dueDateAddition = 90;
			termDesc = 'Net 90';
			break;
		default:
			dueDateAddition = 'none';
			termDesc = 'Not defined';
	}

	const formatZip = (billContact) => {
		if (billContact && billContact.zip) {
			if (billContact.countryId === 218) {
				return billContact.zip.slice(0, 5)
			} else {
				return billContact.zip.trim()
			}
		} else {
			return 'No Zip'
		}
	}

	const existsCheck = (nullReturn, check, field) => {
		if (check && field) {
			return field
		} else if (check) {
			return check
		} else {
			return nullReturn
		}
	}

	const headerData = {
		invoiceNumber: existsCheck('Not defined', invoiceRowData.spectrumInvoiceNumber),
		invoiceDate: existsCheck('Not defined', invoiceRowData.spectrumInvoiceDate, moment.parseZone(invoiceRowData.spectrumInvoiceDate).format('L')),
		purchaseOrderNumber: existsCheck('Not defined', invoiceRowData.purchaseOrderNumber),
		terms: existsCheck('Not defined', termDesc),
		amount: existsCheck('Not defined', invoiceRowData.amount),
		WorkNumber: existsCheck('Not defined', invoiceRowData.workNumber),
		dueDate:
			!invoiceRowData.spectrumInvoiceDate || dueDateAddition === 'none'
				? 'Not defined'
				: invoiceRowData.spectrumInvoiceDate && dueDateAddition !== 0
				? moment.parseZone(invoiceRowData.spectrumInvoiceDate).add(dueDateAddition, 'days').format('L')
				: dueDateAddition === 0
				? moment.parseZone(invoiceRowData.spectrumInvoiceDate).format('L')
				: 'Not defined',
		jobSite: {
			locationName: existsCheck('Location name not defined', workRecord.location_Name),
			locationAddress: existsCheck('Address Not defined', workRecord.location_Address),
			locationAddress2: existsCheck(
				null,
				( workRecord && workRecord.location_Address2 && workRecord.location_Address2.trim().toUpperCase() !== 'NULL' && workRecord.location_Address2.trim() !== ''),
				workRecord.location_Address2),
			locationCity: existsCheck('City Not defined', workRecord.location_City),
			locationState: existsCheck('State Not defined', workRecord.location_State),
			locationStoreNumber: existsCheck('Store number Not defined', workRecord.location_StoreNumber),
			locationZip: existsCheck('Zip Not defined', workRecord.location_Zip)
		},
		soldTo: {
			contactName: existsCheck(
				'No Client name defined',
				(billClient && billClient.name && billClient.spectrumCode),
				`${billClient.name} (${billClient.spectrumCode})`),
			siteNum: existsCheck(null, workRecord.location_StoreNumber),
			contactAddress: existsCheck('No Address', billContact.address),
			contactAddress2: existsCheck(
				null,
				(billContact && billContact.address2 && billContact.address2.trim().toUpperCase() !== 'NULL' && billContact.address2.trim() !== ''),
				billContact.address2),
			contactCity: existsCheck('No City', billContact.city),
			contactState: existsCheck('No State', billContact.state),
			contactZip: formatZip(billContact)
		},
		branch: branchAddress,
		type: branchType
	};

	if (!loading) {
		return (
			<Document>
				<Page size="A4" style={styles.body}>
					<View style={{width: '100%'}}>
						<Header headerData={headerData} invoiceRowData={invoiceRowData} items={items} />
						<Hr />
						<Sow sow={billingSow} />
						<SKUs items={items} />
						<View wrap={false}>
							<Foot invoiceRowData={invoiceRowData} items={items} />
						</View>
					</View>
					<Text
						style={styles.pageNumber}
						render={({pageNumber, totalPages}) => `${pageNumber} / ${totalPages}`}
						fixed
					/>
				</Page>
			</Document>
		);
	}
};
