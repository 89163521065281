import React from 'react';
import moment from 'moment';
import { Page, Text, View, Document, Image, StyleSheet } from '@react-pdf/renderer';
import { generatePDFFromHTML } from '../../../../../../../../../components/pdf/generatePDFFromHTML.js'
import Styled from '../../../../../../../../../components/pdf/pdfStyleWrapper.js'

const TextBase = Styled(Text, {
  fontSize: '7.5pt',
  fontFamily: props => props.bold ? 'Helvetica-Bold' : 'Helvetica',
  textAlign: props => props.textAlign ? props.textAlign : ''
})

const TextSmall = Styled(TextBase, {
  fontSize: '7.5pt'
})

const TextRegular = Styled(TextBase, {
  fontSize: '9pt'
})

const TextExtraLarge = Styled(TextBase, {
  fontSize: '12pt'
})

const Item = Styled(View, {
  flex: '1'
})

const Row = Styled(View, {
  flexDirection: 'row',
  flexWrap: 'wrap',
  display: 'flex',
  marginTop: props => props.marginTop ? props.marginTop : '0px',
  alignItems: props => props.alignItems ? props.alignItems : 'stretch'
})
  
const Logo = Styled(Image, {
  height: '26px',
  width: '80px'
})

// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 20,
    paddingBottom: 50,
    paddingHorizontal: 35,
    flexDirection: 'row',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
});

const Header = (props) => {
  const { jobNumber, locationName, asdContact, location, orderDate, locationContact, scheduledDate } = props.data;
  return (
    <View style={{ width: '100%', paddingBottom: 10 }}>
      <View style={{ marginBottom: 10 }}>
        <Row alignItems={'center'}>
          <Logo src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJYAAAA1CAMAAACUV+KQAAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAE5QTFRFR3BMAEqTAEqTAEqTAEqTAEqTAEqTAEqTAEqTAEqTAEqTAEqTAEqTAEqTAEqTAEqTAEqTAEqTAEqTAEqTAEqTAEqTAEqTAEqTAEqTAEqTzAeFEgAAABl0Uk5TAA5iiPcE7pFQIKJ+MMyu4Jo8FG+31ibCG9sbjU0AAAN7SURBVFjD7VjZdqswDMQbGDCr2fz/P3rb5LZ4kWxDctqXzmMCQpZHo6Uo/vCzEEpS/UDrgVUoWEuV8AzNzBjD0h+cjI0VekSxnZu72NvD9mod54L0XdKtzrVCggdkZ14D79W3sb5ln8EaE/ES1LOhvAe23bwBzVfEyoKx2ah6iLs1l54B6l1xad6CUorn54qPaI2f7kUx+u9XLqu4eRfa72i1S5GIlgoZav9NBvM+POjUa8aKQSa4FbLZiS4z7wR9HLSfizqRiRR42Upnwt/qFt/ydItAfJZxr1/BkifdFfSuPv9vnNvtlv6Jxkcg749f+37xqTnleKVgiTkfWE+f9HyrZE09ZhsHLJRW+Smhm72IyY4YJ+kXEObws7iejN/uF/ljgImL8R3TpLP8nE/05L5fG8fEGoKjSRVYfpbzx2G671eLiDV4Bscr5avxA9rJ7syLnCvqX6N1OpHIkcXO/VlwSF3cOs6bOu0UYTxoQiwymMTr0qsKK3gi7WXDmHCM6BJKtz3WzqF87zzlnHHBjTomaPll7qZbzE89jWhxUKz5eGCC8/+oY/DPmumWnbMPM2KCy8/HbQd1k2uIt3JFGklHlk2U8g7fiZct7nEF0DXvQSWadrzttm2XV/juZYtPDhoEbNhw9vDY59YrfPcCyEWQ9b5jQ41e0x5rzJs81T1DbnM7FM6j8jrCVWDazGJdnca9qjnEI5koqFsDTQxA1Q8Yr/Mart7Rd6j5gnvazemd+Iz0k3Vk5Buu8f0z46wY9jm9k4YZX8aigDcQYjVwznUZhyIV6DtcUYE6sV3juz/I4lqsoVzHCSAcRvZ4r8gx3cxkZgfoE0XSRUxrMJBd4bu/UKJojSAr0KOM4JipdAeM1fAkEvD9oPIJ+8gL/YAEwAZIza3Pl0pNk6TtuAeloRN5fBcvTc4ryPjIzuZAvdLALDneXVkl5k3fK4XzvQT4rm66RbEeFsQQmQQakO/3dpBWa50R72XOXN6eyXZvASLB5hO5QB3p/kQHp4W4s4dk+esmvM0OKTC9tlxj6AY9iFRV5y9vR3SSzUE3ocf1ArXQ1JagQfXdae0z9v8SN2y7tFcyvbmYIltumb1m3Fn4KZfx5bAuTUtlxgTuy4BfBoRSaquTOJDDky88jV1Zy9HLu8KfAM73X8WI8/0XoYzJGop+GJ3BZ+Y/uPgHjlBDmvrMi0YAAAAASUVORK5CYII=' />
          <View style={{ marginLeft: 50 }}>
            <TextExtraLarge bold>Job Number: {jobNumber}</TextExtraLarge>
          </View>
        </Row>
      </View>
      <Row>
        <Item>
          <TextSmall bold>Location Name:</TextSmall>
        </Item>
        <Item>
          <TextSmall>{locationName}</TextSmall>
        </Item>
        <Item>
          <TextSmall bold>ASD Contact:</TextSmall>
        </Item>
        <Item>
          <View>
            <TextSmall>{asdContact.name}</TextSmall>
            <TextSmall>{asdContact.phone} (Phone)</TextSmall>
            <TextSmall>{asdContact.email}</TextSmall>
            <TextSmall>{asdContact.fax} (Fax)</TextSmall>
          </View>
        </Item>
      </Row>
      <Row marginTop={10}>
        <Item>
          <TextSmall bold>Address:</TextSmall>
        </Item>
        <Item>
          <TextSmall>{location.address}</TextSmall>
          <TextSmall>{`${location.city}, ${location.state}, ${location.zip}`}</TextSmall>
        </Item>
        <Item>
          <TextSmall bold>Order Date:</TextSmall>
        </Item>
        <Item>
          <TextSmall>{orderDate}</TextSmall>
        </Item>
      </Row>
      <Row marginTop={10}>
        <Item>
          <TextSmall bold>Location Contact:</TextSmall>
        </Item>
        <Item>
          <TextSmall>{locationContact}</TextSmall>
        </Item>
        <Item>
          <TextSmall bold>Scheduled Date:</TextSmall>
        </Item>
        <Item>
          <TextSmall>{scheduledDate}</TextSmall>
        </Item>
      </Row>
    </View>
  );
}

const Hr = () => (
  <View style={{ width: '100%', border: 1, height: 1 }}>
  </View>
)

const Sow = (props) => {
  const sow = props.sow;
  console.log('generating sow')

  return (
    <View style={{ marginTop: 20, fontSize: 10}}>
      <TextRegular bold> SOW:</TextRegular>
      {sow && sow.length > 0 ? generatePDFFromHTML(sow) : null}
    </View>
  );
}

const SpecialInstructions = (props) => {
  const instructions = props.specialInstructions;

  console.log('generating special instructions')

	return (
		<View style={{ marginTop: 20, fontSize: 10}}>
			<TextRegular bold> Special Instructions:</TextRegular>
      {instructions && instructions.length > 0 ? generatePDFFromHTML(instructions): null}
		</View>
	)
}

const Table = (props) => {
  const { header, rowCount } = props;
  return (
    <View>
      <View style={{ flexDirection: 'row' }}>
        <View style={{ backgroundColor: 'gray', flex: 1, height: 16, border: 1 }}>
          <TextSmall textAlign={'center'} bold>{header.firstItem}</TextSmall>
        </View>
        <View style={{ backgroundColor: 'gray', flex: 1, height: 16, borderBottom: 1, borderTop: 1, borderRight: 1 }}>
          <TextSmall textAlign={'center'} bold>{header.secondItem}</TextSmall>
        </View>
      </View>
      {[...Array(rowCount)].map((elm, index) => (
        <View key={index + 1} style={{ flexDirection: 'row' }}>
          <View style={{ flex: 1, height: 16, borderBottom: 1, borderLeft: 1, borderRight: 1 }}></View>
          <View style={{ flex: 1, height: 16, borderBottom: 1, borderRight: 1 }}></View>
        </View>)
      )}
    </View>
  )
};

const MaterialsUsed = () => (
  <View style={{ marginTop: 10 }}>
    <TextRegular bold> Materials Used:</TextRegular>
    <Table header={{ firstItem: 'Product', secondItem: 'Unit' }} rowCount={5} />
    <TextSmall textAlign={'center'} bold> All Work Described On This Work Order Has Been Completed</TextSmall>
  </View>
)

const Signature = () => (
  <View style={{ marginTop: 30 }}>
    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
      <View style={{ width: 225 }}>
        <Hr />
        <TextSmall textAlign={'center'} bold>Site Contact Sign & Print Name</TextSmall>
      </View>
      <View style={{ bottom: 15 }}>
        <TextSmall bold> Job </TextSmall>
      </View>
      <View style={{ width: 225 }}>
        <Hr />
        <TextSmall textAlign={'justify'} bold>Date {'           '} Start Time {'           '} End Time</TextSmall>
      </View>
    </View>
    <View style={{ marginTop: 40, flexDirection: 'row', justifyContent: 'space-between' }}>
      <View style={{ width: 225 }}>
        <Hr />
        <TextSmall textAlign={'center'} bold>Technician Sign & Print Name</TextSmall>
      </View>
      <View style={{ bottom: 15 }}>
        <TextSmall bold> Travel </TextSmall>
      </View>
      <View style={{ width: 225 }}>
        <Hr />
        <TextSmall textAlign={'justify'} bold>Date {'           '} Start Time {'           '} End Time</TextSmall>
      </View>
    </View>
  </View>
)

const Foot = (props) => {
  const { asdContact, branch } = props.data;
  return (
    <View style={{ marginTop: 15 }}>
      <TextSmall textAlign={'center'} bold>Final Payment is based solely on information provided by you on this work order</TextSmall>
      <Hr />
      <View style={{ marginTop: 10 }}>
        <TextRegular textAlign={'center'} bold>Submit completed & signed form within 24 hours of completion of work to {'\n'} {asdContact.name} {asdContact.email}</TextRegular>
      </View>
      <View style={{ marginTop: 10 }}>
        <TextSmall textAlign={'center'} bold>{branch}</TextSmall>
      </View>
    </View>
  );
};

const branchAddress = {
	GA: 'Automated Systems Design, Inc. 1111 Alderman Drive, Suite 300, Alpharetta, GA 30005',
	NC: 'Automated Systems Design, Inc. 1225 25th Street Place, Hickory, NC 28603'
};

const WorkOrderPDF = (props) => {

	const getBranchAddress = (branch) => {
		if (branchAddress[branch]) {
			return branchAddress[branch];
		}
		return '';
	};

    const { loading, workOverview, tripInfo } = props;
    const pdfData = {
      jobNumber: workOverview.workNumber ? workOverview.workNumber : 'Not defined',
      locationName: workOverview.location_Name ? workOverview.location_Name : 'Not defined',
      location: {
        address: workOverview.location_Address ? workOverview.location_Address : 'Not defined',
        address2: workOverview.location_Address2 ? workOverview.location_Address2 : ' ',
        city: workOverview.location_City ? workOverview.location_City : ' ',
        state: workOverview.location_State ? workOverview.location_State : ' ',
        storeNumber: workOverview.location_StoreNumber ? workOverview.location_StoreNumber : ' ',
        zip: workOverview.location_Zip ? workOverview.location_Zip : ' ',
      },
      locationContact: workOverview.billingContact_Name ? workOverview.billingContact_Name : 'Not defined',
      asdContact: {
        name: workOverview.workManager_Name ? workOverview.workManager_Name : 'Not defined',
        phone: workOverview.workManager_Phone ? workOverview.workManager_Phone : '(000)000-0000',
        email: workOverview.workManager_Email ? workOverview.workManager_Email : 'Email not defined ',
        fax: workOverview.workManager_Fax ? workOverview.workManager_Fax : 'Fax not defined',
      },
      orderDate: workOverview.createdOn ? moment(workOverview.createdOn).format('MM/DD/YYYY') : 'Not defined',
      scheduledDate: tripInfo.requestDate ? moment(tripInfo.requestDate).format('MM/DD/YYYY h:mm a') : 'Not defined',
      sow: tripInfo.aasdiSow ? tripInfo.aasdiSow : '',
      branch: workOverview.branch ? getBranchAddress(workOverview.branch) : '',
      specialInstructions: tripInfo.specInst ? tripInfo.specInst : ''
    };

    if (!loading) {
      return (
				<Document>
					<Page size="A4" style={styles.body}>
						<View style={{ width: '100%' }}>
							<Header data={pdfData} />
							<Hr />
							<Sow sow={pdfData.sow} />
							<SpecialInstructions specialInstructions={pdfData.specialInstructions} />
							<MaterialsUsed />
							<View minPresenceAhead>
								<Signature />
								<Foot data={pdfData} />
							</View>
						</View>
						<Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
							`${pageNumber} / ${totalPages}`
						)} fixed />
					</Page>
				</Document>);
    } 
}

export default WorkOrderPDF;
